import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Context } from "../../index";
import ProductItem from "../ProductItem/ProductItem";
import './ProductList.scss'
import './ProductListMedia.scss'

const ProductList = observer(({products}) => {
    const {product} = useContext(Context)
    return (
        <div className="product__list">
            {products ? products.map(product => {
                return <ProductItem key={product.id} productItem={product}/> 
            }) 
            :
            product.products.length > 0 && product.products.map(product => 
                <ProductItem key={product.id} productItem={product}/>
            )}
        </div>
    );
});

export default ProductList;