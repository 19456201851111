import { observer } from "mobx-react-lite";
import BasketItem from "../BasketItem/BasketItem";
import { removeBasketProduct } from "../../http/basketApi";
import './BasketList.scss'
import { useContext } from "react";
import { Context } from "../../index";
import './BasketListMedia.scss'

const BasketList = observer(({basketItems, setBasketItems, setTotalPrice}) => {
    const {notification, loader} = useContext(Context)

 

    const removeProduct = async (id) => {
        try {
            const {message} = await removeBasketProduct(id);
            setBasketItems(prev => prev.filter(item => item.basketProductId !== id));
            notification.showNotification(message);
        } catch (e) {
            notification.showNotification(e.message);
        }
    };

    const handleEmptyBasket = () => {
        setTimeout(() => {
            loader.hideLoader();
        }, 200)
        return (
            <h2 className="basket__list__empty">
                {`Coșul tău este pustiu :(`}
            </h2>
        );
    }

    return (
        <div className="basket__list">
            {basketItems.length > 0 ? 
                basketItems.map((item, i) => {
                    if (i == basketItems.length - 1) {
                        loader.hideLoader();
                    }
                    return (
                        <BasketItem 
                            key={item.basketProductId} 
                            product={item} 
                            setBasketItems={setBasketItems}
                            removeProduct={removeProduct} 
                            id={item.basketProductId}
                            setTotalPrice={setTotalPrice}
                        />
                    )
                })
            :
                handleEmptyBasket()
            }
        </div>
    );
});

export default BasketList;