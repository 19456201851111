import { observer } from 'mobx-react-lite';
import './UserItem.scss'
import './UserItemMedia.scss'
import { useNavigate } from 'react-router-dom';
import { USER_ROUTE } from '../../utils/consts';
import { format } from 'date-fns';

const UserItem = observer(({userData}) => {
    const navigate = useNavigate();

    // Форматируем дату регистрации пользователя
    const formattedDate = format(new Date(userData.createdAt), 'dd.MM.yyyy');

    return (
        <div className='user__item'>
            <div 
                className="user__name"
                onClick={() => navigate(USER_ROUTE + "/" + userData.id)}
            >
                Numele: {userData.name}
            </div>

            <div className="user__phone">
                Numărul: 0{userData.phone}
            </div>

            <div className="user__purchased">
                Comenzi: {userData.ordersCount}
            </div>
            
            <div className="user__spended">
                A cheltuit: {userData.totalSpend} lei
            </div>

            <div className="user__registered">
                Sa înregistrat: {formattedDate}
            </div>

        </div>
    );
});

export default UserItem;