import { makeAutoObservable, runInAction } from 'mobx';

export default class NotificationStore {
    notification = null;
    timeoutId = null;
    keyId = 0;

    constructor() {
        makeAutoObservable(this);
    }

    showNotification(message) {
        runInAction(() => {
            this.notification = message;
            this.keyId += 1;

            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            this.timeoutId = setTimeout(() => {
                runInAction(() => {
                    this.notification = null;
                    this.timeoutId = null;
                });
            }, 3000);
        });
    }
}