import React, { useEffect, useState } from 'react';
import './ConfirmationalModal.scss';
import './ConfirmationalModalMedia.scss';

const ConfirmationalModal = ({isVisible, setIsVisible, onConfirm, onCancel, text}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [secondsLeft, setSecondsLeft] = useState(3);

  useEffect(() => {
    if (isVisible) {
      // Сбрасываем состояние при каждом открытии модального окна
      setIsButtonDisabled(true);
      setSecondsLeft(3);

      const interval = setInterval(() => {
        setSecondsLeft((prev) => {
          if (prev === 1) {
            setIsButtonDisabled(false);
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isVisible]);

  return (
    
    <div 
      className={isVisible ? "confirm__modal show" : "confirm__modal"}
      onMouseDown={(e) => setIsVisible(false)}
    >
      <div 
        className={isVisible ? "confirm__modal__content show" : "confirm__modal__content"}
        onMouseDown={e => e.stopPropagation()}
      >
        <h2>Confirmă acțiunea</h2>
        <div className="confirm__modal__text">
          {text}
        </div>
        <div className="confirm__modal__actions">
          <button 
            onClick={onCancel}
            className='confirm__modal__btn-cancel'
          >
            Anulează
          </button>
          <button 
            onClick={onConfirm} 
            disabled={isButtonDisabled}
            className='confirm__modal__btn'
          >
            {isButtonDisabled ? (
              <div className='confirm__modal__load'>
                <div className="confirm__modal__spinner" />
                <span>{secondsLeft}</span>
              </div>
            ) : (
              'Confirmă'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationalModal;