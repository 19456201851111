import {$authHost} from "./index"

export const createOrder = async (order) => {
    const {data} = await $authHost.post('api/order', order, { withCredentials: true })
    return data
}

export const fetchOrders = async () => {
    const {data} = await $authHost.get(`api/order`, { withCredentials: true })
    return data
}

export const removeOrder = async (id) => {
    const {data} = await $authHost.delete(`api/order/${id}`, { withCredentials: true })
    return data
}

