import { useContext, useEffect, useState } from "react";
import {useNavigate, useParams} from 'react-router-dom'
import { fetchOneProduct, removeProductInfo } from "../../http/productApi";
import { addBasketProduct } from "../../http/basketApi";
import { Context } from "../../index";
import './ProductPage.scss'
import ToShop from "../../components/ToShop/ToShop";
import { LOGIN_ROUTE, REGISTRATION_ROUTE } from "../../utils/consts";
import ChangeProductInfo from "../../components/modals/ChangeProductInfo";
import ChangeProductImg from "../../components/modals/ChangeProductImg";
import ChangeProductType from "../../components/modals/ChangeProductType/ChangeProductType";
import './ProductPageMedia.scss'
import Loader from "../../components/Loader/Loader";

const ProductPage = () => {
    const [selectedProduct, setSelectedProduct] = useState({info: []});
    const {user, product, notification, loader} = useContext(Context);
    const {id} = useParams();
    const navigate = useNavigate();

    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [isTypeModalVisible, setIsTypeModalVisible] = useState(false);

    const [infoHover, setInfoHover] = useState(false);
    const [typeHover, setTypeHover] = useState(false);

    const [selectedInfoId, setSelectedInfoId] = useState(0);
    const [selectedInfoIndex, setSelectedInfoIndex] = useState(0);
    const [isCreate, setIsCreate] = useState(false);

    const [currentType, setCurrentType] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [isImgLoaded, setIsImgLoaded] = useState(false);

    useEffect(() => {
        loader.showLoader(false)
        fetchOneProduct(id)
        .then(data => {
            setSelectedProduct(data)
            return data;
        })
        .then(data => setCurrentType(product.types.find(type => type.id == data.typeId)))
        .then(() => setIsLoading(false))
        .finally(() => {
            setTimeout(() => {
                setIsLoading(false)
                loader.hideLoader()
            }, 1000);
        })
    }, [])

    if (isLoading) {
        return <Loader/>
      }

    const addProductInBasket = async (prod) => {
        loader.showLoader(false);
        const data = await addBasketProduct(prod)
        if (data.basketProduct) {
            user.setBasket({...user.basket, basket_products: [...user.basket.basket_products, data.basketProduct]})
        }
        notification.showNotification(data.message)
        setTimeout(() => {
            loader.hideLoader();
        }, 200)
    }

    const deleteInfo = async (infoId) => {
        const data = await removeProductInfo(infoId);

        setSelectedProduct(prev => {
            const newInfo = prev.info.filter(item => item.id !== infoId);
    
            return {
                ...prev,
                info: newInfo,
            }
        });
        notification.showNotification(data.message)
        setInfoHover(false);
    };

    const handleImgLoad = () => {
        setIsImgLoaded(true);
    }

    return (
        <div className="product__page">
            <div id="container">
            <ToShop></ToShop>
                <div className="product__page__wrapper">
                    
                    <img 
                        className={`product__page__img ${isImgLoaded && 'active'}`} 
                        src={process.env.REACT_APP_API_URL + `server-static/${selectedProduct.img}`} 
                        alt="product_img"
                        onLoad={handleImgLoad}
                        onClick={() => user.user.role == 'ADMIN' && setIsImageModalVisible(true)}
                    />
                    
                    <div className="product__page__about__wrapper">
                        

                        <div className="product__page__info__wrapper">
                            <h3 className="product__page__name">
                                {selectedProduct.name}
                            </h3>
                            {
                        user.user.role == 'ADMIN' 
                        &&
                        <h3 
                            className="product__page__type"
                            onMouseEnter={() => user.user.role == 'ADMIN' && setTypeHover(true)}
                            onMouseLeave={() => user.user.role == 'ADMIN' && setTypeHover(false)}
                        >
                            Tip: {currentType.name}
                            {typeHover
                            && 
                            <span>
                                <div 
                                    className="product__page__type__edit"
                                    onClick={() => setIsTypeModalVisible(true)}
                                >
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 15.0129L8.413 14.9979L18.045 5.4579C18.423 5.0799 18.631 4.5779 18.631 4.0439C18.631 3.5099 18.423 3.0079 18.045 2.6299L16.459 1.0439C15.703 0.287902 14.384 0.291902 13.634 1.0409L4 10.5829V15.0129ZM15.045 2.4579L16.634 4.0409L15.037 5.6229L13.451 4.0379L15.045 2.4579ZM6 11.4169L12.03 5.4439L13.616 7.0299L7.587 13.0009L6 13.0059V11.4169Z"/>
                                        <path d="M2 19H16C17.103 19 18 18.103 18 17V8.332L16 10.332V17H5.158C5.132 17 5.105 17.01 5.079 17.01C5.046 17.01 5.013 17.001 4.979 17H2V3H8.847L10.847 1H2C0.897 1 0 1.897 0 3V17C0 18.103 0.897 19 2 19Z"/>
                                    </svg>
                                </div>
                            </span>
                            }
                        </h3>
                        }
                            {selectedProduct.info.map((info, index) => 
                            
                                <h3 
                                    className="product__page__info"
                                    key={info.id}
                                    onMouseEnter={() => user.user.role == 'ADMIN' && setInfoHover(true)}
                                    onMouseLeave={() => user.user.role == 'ADMIN' && setInfoHover(false)}
                                >
                                    <div className="product__page__info__item">{info.title} </div> <div className="product__page__info-descr"> {info.description}</div>
                                    {infoHover
                                    && 
                                    <span>
                                        <div 
                                            className="product__page__info__edit"
                                            onClick={() => {
                                                setIsCreate(false);
                                                setIsInfoModalVisible(true);
                                                setSelectedInfoId(info.id);
                                                setSelectedInfoIndex(index);
                                            }}
                                        >
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 15.0129L8.413 14.9979L18.045 5.4579C18.423 5.0799 18.631 4.5779 18.631 4.0439C18.631 3.5099 18.423 3.0079 18.045 2.6299L16.459 1.0439C15.703 0.287902 14.384 0.291902 13.634 1.0409L4 10.5829V15.0129ZM15.045 2.4579L16.634 4.0409L15.037 5.6229L13.451 4.0379L15.045 2.4579ZM6 11.4169L12.03 5.4439L13.616 7.0299L7.587 13.0009L6 13.0059V11.4169Z"/>
                                                <path d="M2 19H16C17.103 19 18 18.103 18 17V8.332L16 10.332V17H5.158C5.132 17 5.105 17.01 5.079 17.01C5.046 17.01 5.013 17.001 4.979 17H2V3H8.847L10.847 1H2C0.897 1 0 1.897 0 3V17C0 18.103 0.897 19 2 19Z"/>
                                            </svg>
                                        </div>
                                        
                                        <div 
                                            className="product__page__info__delete"
                                            onClick={() => deleteInfo(info.id)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="23px" viewBox="0 0 24 24"><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21q.512.078 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48 48 0 0 0-3.478-.397m-12 .562q.51-.088 1.022-.165m0 0a48 48 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a52 52 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a49 49 0 0 0-7.5 0"></path></svg>

                                        </div>

                                    </span>}
                                </h3>
                            )}
                            {user.user.role == 'ADMIN' 
                            &&
                            <h3 className="product__page__info">
                                <button 
                                    className="product__page__info__add"
                                    onClick={() => {
                                        setIsCreate(true);
                                        setIsInfoModalVisible(true);
                                    }}
                                >
                                    Adaugă info+
                                </button>
                            </h3>
                            }
                        </div>

                        <div className="product__page__buy__wrapper">

                            <h3 className="product__page__price-old">
                                { selectedProduct.oldPrice == 0 ? '' : `${selectedProduct.oldPrice} lei` }
                            </h3>

                            <h3 className="product__page__price">
                                {selectedProduct.price} lei
                            </h3>

                            <button
                                className="product__page__buy__button"
                                variant={"outline-dark"}
                                onClick={() => {
                                    user.isAuth 
                                    ?
                                    addProductInBasket({
                                        basketId: user.basket.id,
                                        productId: selectedProduct.id
                                    })
                                    :
                                    navigate(LOGIN_ROUTE)
                                }}
                            >
                                Adaugă în coș
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ChangeProductInfo 
                isVisible={isInfoModalVisible} 
                setIsVisible={setIsInfoModalVisible} 
                infoId={selectedInfoId}
                infoIndex={selectedInfoIndex}
                setCurrentProduct={setSelectedProduct}
                productId={selectedProduct.id}
                isCreate={isCreate}
                setIsCreate={setIsCreate}
            />
            <ChangeProductImg
                isVisible={isImageModalVisible} 
                setIsVisible={setIsImageModalVisible}
                setCurrentProduct={setSelectedProduct}
                id={selectedProduct.id}
            />
            <ChangeProductType 
                isVisible={isTypeModalVisible}
                setIsVisible={setIsTypeModalVisible}
                id={selectedProduct.id}
                categoryId={selectedProduct.categoryId}
                currentType={currentType}
                setCurrentType={setCurrentType}
            />

        </div>
    );
};

export default ProductPage;
