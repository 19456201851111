import React, { useContext } from 'react';
import { Context } from '../../index';
import './Notification.scss'
import { observer } from 'mobx-react-lite';

const Notification = observer(() => {
    const { notification } = useContext(Context)

    return (
        notification.notification && (
            <div className='notification'>
                {notification.notification}
                <div className="notification__line">
                    <div 
                        key={notification.keyId}
                        className="notification__line__content"></div>
                </div>
            </div>
        )
    );
});

export default Notification;