import { jwtDecode } from "jwt-decode";
import {makeAutoObservable} from "mobx";

export default class UserStore {
    constructor() {
        this._isAuth = false
        this._role = ''
        this._user = {}
        this._userBasket = []
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }
    setUser(user) {
        this._user = user
    }
    setRole(role) {
        this._role = role
    }

    setRoleFromToken() {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        if (token) {
            try {
                const decodedToken = jwtDecode(token, process.env.SECRET_KEY); // Декодируем токен с использованием секретного ключа
                const userRole = decodedToken.role; // Извлекаем роль из токена
                this.setRole(userRole); // Устанавливаем роль пользователя
            } catch (error) {
                console.error("Error decoding token:", error);
            }
        }
    }

    setBasket(basket) {
        this._userBasket = basket
    }


    get isAuth() {
        return this._isAuth
    }
    get user() {
        return this._user
    }
    get role() {
        return this._role
    }
    get basket() {
        return this._userBasket
    }
}