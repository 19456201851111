import { observer } from "mobx-react-lite";
import ProductList from "../../components/ProductList/ProductList";
import { fetchOneCategory, removeOneCategory } from "../../http/productApi";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../index";
import TypeBar from "../../components/TypeBar/TypeBar";
import ToShop from "../../components/ToShop/ToShop";
import './CategoryPage.scss'
import './CategoryPageMedia.scss'
import ChangeCategoryName from "../../components/modals/ChangeCategoryName/ChangeCategoryName";
import { SHOP_ROUTE } from "../../utils/consts";
import ConfirmationalModal from "../../components/modals/ConfirmationalModal/ConfirmationalModal";

const CategoryPage = observer(() => {
    const {id} = useParams()
    const {product, user, notification, loader} = useContext(Context)
    const [products, setProducts] = useState([])
    const navigate = useNavigate()

    const [categoryHover, setCategoryHover] = useState(false)
    const [categoryModal, setCategoryModal] = useState(false)
    const [currentCategoryName, setCurrentCategoryName] = useState('')
    const [isConfirmDeleteCategoryVisible, setIsConfirmDeleteCategoryVisible] = useState(false)

    const [isFirstLoad, setIsFirstLoad] = useState(true)

    const deleteCategory = async (id) => {
      try {
        loader.showLoader(false);
        const data = await removeOneCategory(id)
          product.setCategories(product.categories.filter(category => {
            return category.id !== id;
          } 
        ));
        setTimeout(() => {
          navigate(SHOP_ROUTE);
          window.location.reload();
        }, 500);
        return data
      } catch (e) {
        return console.log(e)
      }
    } 

    const handleDeleteCategoryConfirm = async () => {
      await deleteCategory(id)
      setIsConfirmDeleteCategoryVisible(false)
      notification.showNotification('Categoria a fost ștearsă')
    }

    const handleDeleteCategoryCancel = () => {
      setIsConfirmDeleteCategoryVisible(false);
    }

    useEffect(() => {
      if (isFirstLoad) {
        loader.showLoader(false);  // Для первого рендера показываем лоадер с false
      } else {
        loader.showLoader(true);   // Для последующих срабатываний показываем лоадер с true
      }
    
      setTimeout(() => {
          loader.hideLoader();
      }, 1000); 
      const getCategoryData = async () => {
        const data = await fetchOneCategory(id);
        product.setSelectedCategory(data);
        setCurrentCategoryName(product.selectedCategory.name);
      }
      getCategoryData();
      setProducts(product.products.filter(p => Object.keys(product.selectedType).length > 0 ? p.categoryId == id && p.typeId == product.selectedType.id : p.categoryId == id ))
      setIsFirstLoad(false);
    }, [product.selectedType])

    return (
        <div className="category__page">
          <div className="category__page__container">
            <ToShop></ToShop>
            <h2 
              onMouseEnter={() => setCategoryHover(true)}
              onMouseLeave={() => setCategoryHover(false)}
              className="category__page__title">
              {currentCategoryName}
              {user.user.role === "ADMIN" 
              && 
              categoryHover
              && 
              <span>
                <div 
                  className="category__page__title__edit"
                  onClick={() => {
                    setCategoryModal(true);
                  }}
                >
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 15.0129L8.413 14.9979L18.045 5.4579C18.423 5.0799 18.631 4.5779 18.631 4.0439C18.631 3.5099 18.423 3.0079 18.045 2.6299L16.459 1.0439C15.703 0.287902 14.384 0.291902 13.634 1.0409L4 10.5829V15.0129ZM15.045 2.4579L16.634 4.0409L15.037 5.6229L13.451 4.0379L15.045 2.4579ZM6 11.4169L12.03 5.4439L13.616 7.0299L7.587 13.0009L6 13.0059V11.4169Z"/>
                    <path d="M2 19H16C17.103 19 18 18.103 18 17V8.332L16 10.332V17H5.158C5.132 17 5.105 17.01 5.079 17.01C5.046 17.01 5.013 17.001 4.979 17H2V3H8.847L10.847 1H2C0.897 1 0 1.897 0 3V17C0 18.103 0.897 19 2 19Z"/>
                  </svg>
                </div>

              </span>}
            </h2>
                
            {user.user.role === "ADMIN"
            &&
            <h3 
              onClick={() => {
                setIsConfirmDeleteCategoryVisible(true);
              }}
              className="category__page__delete"
            >
              șterge categoria
            </h3>
            }

            <TypeBar categoryId={id}/>

            <div className="category__page__product__list">
              <ProductList products={products}/>
            </div>
          </div>

          <ConfirmationalModal 
            isVisible={isConfirmDeleteCategoryVisible} 
            setIsVisible={setIsConfirmDeleteCategoryVisible} 
            onConfirm={handleDeleteCategoryConfirm} 
            onCancel={handleDeleteCategoryCancel} 
            text={'După ce vei șterge categoria, ser vor șterge toate produsele și tipurile care se referă la ea.'}
          />

          <ChangeCategoryName 
            isVisible={categoryModal} 
            setIsVisible={setCategoryModal} 
            id={id} 
            setCurrentCategoryName={setCurrentCategoryName} 
          />
        </div>
    );
});

export default CategoryPage;