import { decreaseTotalSpend, decrementOrdersCount } from '../../http/userApi';
import { removeUserOrder } from '../../http/userOrderApi';
import { format } from 'date-fns';
import './UserOrder.scss'
import './UserOrderMedia.scss'
import { useContext, useState } from 'react';
import { Context } from '../../index';
import ConfirmationalModal from '../modals/ConfirmationalModal/ConfirmationalModal';

const UserOrder = ({order, updateUserPage}) => {
    const {loader} = useContext(Context)
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState();

    const formattedTime = format(new Date(order.createdAt), 'HH:mm');
    const formattedDate = format(new Date(order.createdAt), 'dd.MM.yy');

    let deliveryMethod;

    if (order.delivery == 1) {
        deliveryMethod = 'Livrare'
    }

    if (order.delivery == 2) {
        deliveryMethod = 'Pe loc'
    }

    if (order.delivery == 3) {
        deliveryMethod = 'La pachet'
    }

    const deleteOrder = async (id, userId) => {
        try {
            loader.showLoader();
            await decrementOrdersCount(userId);
            await decreaseTotalSpend(userId, id);
            await removeUserOrder(id);
        } catch (e) {
            console.log(e.message);
        } finally {
            loader.hideLoader();
            updateUserPage();
        }
    };

    const handleCancel = () => {
        setIsConfirmModalVisible(false)
    };

    return (
        <div className="user__page__order">
            <div className="user__page__order__wrapper__time">
                <div className="user__page__order__date">
                    Data: {formattedDate}
                </div>
                <div className="user__page__order__time">
                    A fost livrat la: {formattedTime}
                </div>
            </div>
            
             <div className="user__page__order__products">
                <span>Produse:</span> <br /> {order.orderProducts}
             </div>

            <div className="user__page__order__delivery">
                {deliveryMethod}
            </div>

            <div className="user__page__order__address">
                {order.address}
            </div>

             <div className="user__page__order__sum">
                Suma comenzii: {order.orderSum} Lei
             </div>

             <div className="user__page__order__remove" onClick={() => setIsConfirmModalVisible(true)}>
                <svg className="basket__item__remove__icon" fill="none" viewBox="6.5 2 29 29" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.2085 10.2083L28.7918 24.7917M14.2085 24.7917L28.7918 10.2083" stroke="#C82A2A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <ConfirmationalModal
                isVisible={isConfirmModalVisible} 
                setIsVisible={setIsConfirmModalVisible} 
                onConfirm={() => deleteOrder(order.id, order.userId)} 
                onCancel={handleCancel} 
                text={'Comanda va fi ștearsă!'} 
            />
        </div>
    );
};

export default UserOrder;