export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const SHOP_ROUTE = '/'
export const BASKET_ROUTE = '/basket'
export const PRODUCT_ROUTE = '/product'
export const CATEGORY_ROUTE = '/category'
export const ORDER_ROUTE ='/order'
export const STATISTIC_ROUTE ='/statistic'
export const USER_ROUTE ='/user'
export const POLICY_ROUTE ='/policy'
