
import { observer } from "mobx-react-lite";
import CategoryBar from "../../components/CategoryBar/CategoryBar";
import CategoryList from "../../components/CategoryList/CategoryList";
import PromoSlider from "../../components/PromoSlider/PromoSlider";
import './Shop.scss'
import { useContext, useEffect } from "react";
import { Context } from "../../index";


const Shop = observer(() => {
    const {loader} = useContext(Context)

    useEffect(() => {
        loader.showLoader(false);  // Для первого рендера показываем лоадер с false
          
        
        setTimeout(() => {
            loader.hideLoader();
        }, 1000);
    }, [])

    return (
        <div className="shop">
            <PromoSlider/>
            <div className="shop__content">
              <CategoryBar/>
              <div id="container">
              <CategoryList/>
              </div>
            </div>
        </div>
    );
});

export default Shop;