import { useContext, useEffect, useState } from "react";
import { Context } from "../../index";
import CategoryItem from "../CategoryItem/CategoryItem";
import './CategoryList.scss'
import './CategoryListMedia.scss'
import { observer } from "mobx-react-lite";

const CategoryList = observer(() => {
    const {product} = useContext(Context)

    useEffect(() => {
        product.setSelectedCategory({})
        product.setSelectedType({})
    }, [])

    return (
        <div className="category__list">
                {product.categories.map((category) => (
                    <CategoryItem key={category.id} title={category.name} categoryId={category.id} category={category}/>
                ))}
        </div>
    );
});

export default CategoryList;