import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LOGIN_ROUTE, REGISTRATION_ROUTE, SHOP_ROUTE } from "../../utils/consts";
import { changePassword, login, registration } from "../../http/userApi";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { fetchBasket } from "../../http/basketApi"
import './Auth.scss'
import './AuthMedia.scss'
import { sendSmsCode, sendSmsResetCode } from "../../http/smsApi";
import SmsResend from "./SmsResend";



const Auth = observer(() => {
    const {user, notification, loader} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = location.pathname === LOGIN_ROUTE; 
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [isSmsSended, setIsSmsSended] = useState(false)
    const [code, setCode] = useState('')
    const [resetPass, setResetPass] = useState(false)
    const [isResetPassSended, setIsResetPassSended] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [verifyPassword, setVerifyPassword] = useState('')

    useEffect(() => {
        loader.showLoader(false);
        setTimeout(() => {
            loader.hideLoader();
        }, 500)
    }, []);

    const sendSms = async () => {
        loader.showLoader(false);
        try {
            if (!name) {
                loader.hideLoader();
                return notification.showNotification('Întroduceți numele!')
            }

            if (name.length < 2) {
                loader.hideLoader();
                return notification.showNotification('Numele este prea scurt!')
            }

            if (name.length > 21) {
                loader.hideLoader();
                return notification.showNotification('Numele este prea lung!')
            }

            if (!phone) {
                loader.hideLoader();
                return notification.showNotification('Întroduceți numărul!')
            }

            if (phone.length > 9) {
                loader.hideLoader();
                return notification.showNotification('Numărul este prea lung!')
            }

            if (phone.length < 9) {
                return notification.showNotification('Numărul este prea scurt!')
            }

            if (!password) {
                loader.hideLoader();
                return notification.showNotification('Întroduceți parola!')
            }

            if (password.length < 6) {
                loader.hideLoader();
                return notification.showNotification('Parola e prea scurtă!')
            }

            if (password.length > 129) {
                loader.hideLoader();
                return notification.showNotification('Parola e prea lungă!')
            }

            if (!isChecked) {
                loader.hideLoader();
                return notification.showNotification('Acceptă politica de confidelitate!')
            }

            const data = await sendSmsCode(name, +phone, password);

            if (data.status === 200) {
                setIsSmsSended(true);
            }
            notification.showNotification(data.message)
            loader.hideLoader();
        } catch (e) {
            loader.hideLoader();
            return notification.showNotification(e.message);
        }
    };

    const sendResetSms = async () => {
        try {
            loader.showLoader(false);
            if (!phone) {
                loader.hideLoader();
                return notification.showNotification('Întroduceți numărul!')
            }

            if (phone.length > 9) {
                loader.hideLoader();
                return notification.showNotification('Numărul este prea lung!')
            }

            if (phone.length < 9) {
                return notification.showNotification('Numărul este prea scurt!')
            }
            const data = await sendSmsResetCode(+phone);

            if (data.status === 200) {
                setResetPass(false);
                setIsResetPassSended(true);
            }
            notification.showNotification(data.message)
            loader.hideLoader();

        } catch (e) {
            loader.hideLoader();
            return notification.showNotification(e.message);
        }
    };

    const resetPassword = async () => {
        try {
            loader.showLoader(false);

            if (!newPassword) {
                return notification.showNotification(`Introduceți parola nouă!`)
            }

            if (!verifyPassword) {
                return notification.showNotification(`Verificați parola!`)
            }
            
            if (newPassword.length < 4 || newPassword.length > 50) {
                return notification.showNotification(`Parola nu poate fi mai scurtă de 4 simbole sau mai lungă de 50!`)
            }

            if (newPassword !== verifyPassword) {
                return notification.showNotification(`Parolele nu sunt tot una!`)
            }

            if (!code) {
                return notification.showNotification(`Introduceți codul din sms!`)
            }

            if (code.length < 6 || code.length > 6) {
                return notification.showNotification(`Introduceți codul din 6 cifre!`)
            }

            const data = await changePassword(newPassword, +phone, code);

            setIsSmsSended(false);
            setIsResetPassSended(false);
            setResetPass(false);

            return notification.showNotification(data.message);

        } catch (e) {
            loader.hideLoader();
            return notification.showNotification(e.message)
        } finally {
            loader.hideLoader();
        }
    };

    const authorization = async () => {
        try {
            loader.showLoader(false);
            let data;
            if (isLogin) {
                try {
                    if (!phone) {
                        loader.hideLoader();
                        return notification.showNotification('Întroduceți numărul!')
                    }

                    if (phone.length > 9) {
                        loader.hideLoader();
                        return notification.showNotification('Numărul este prea lung!')
                    }
                    if (phone.length < 9) {
                        return notification.showNotification('Numărul este prea scurt!')
                    }
    
                    if (!password) {
                        loader.hideLoader();
                        return notification.showNotification('Întroduceți parola!')
                    }
    
                    data = await login(+phone, password)
                    notification.showNotification(data.message)

                } catch (e) {
                    loader.hideLoader();
                    return notification.showNotification(e.message)
                }

            } else {
                try {

                    if (!name) {
                        loader.hideLoader();
                        return notification.showNotification('Introduceți numele!')
                    }

                    if (name.length < 2) {
                        loader.hideLoader();
                        return notification.showNotification('Numele este prea scurt!')
                    }

                    if (name.length > 21) {
                        loader.hideLoader();
                        return notification.showNotification('Numele este prea lung!')
                    }

                    if (!phone) {
                        loader.hideLoader();
                        return notification.showNotification('Introduceți numărul!')
                    }

                    if (phone.length > 9) {
                        loader.hideLoader();
                        return notification.showNotification('Numărul este prea lung!')
                    }
                    
                    if (phone.length < 9) {
                        return notification.showNotification('Numărul este prea scurt!')
                    }

                    if (!password) {
                        loader.hideLoader();
                        return notification.showNotification('Introduceți parola!')
                    }

                    if (password.length < 6) {
                        loader.hideLoader();
                        return notification.showNotification('Parola e prea scurtă!')
                    }

                    if (password.length > 129) {
                        loader.hideLoader();
                        return notification.showNotification('Parola e prea lungă!')
                    }

                    if (!isChecked) {
                        loader.hideLoader();
                        return notification.showNotification('Acceptă politica de confidelitate!')
                    }

                    if (!code) {
                        loader.hideLoader();
                        return notification.showNotification('Introdu codul din sms!')
                    }
                    
                    if (code.length !== 6) {
                        loader.hideLoader();
                        return notification.showNotification('Codul trebuie să fie din 6 cifre!')
                    }

                    data = await registration(name, +phone, password, code)
                    notification.showNotification(data.message)
                } catch (e) {
                    loader.hideLoader();
                    return notification.showNotification(e.message)
                }
            }
            if (!data.userData) {
                loader.hideLoader();
                return notification.showNotification(data.message)
            }
            setTimeout(async () => {
                user.setUser(data.userData)
                user.setIsAuth(true)
                user.setRole(data.userData.role)
                const basketData = await fetchBasket(user.user.id)
                user.setBasket(basketData)
                navigate(SHOP_ROUTE)
            }, 500);

        } catch (e) {
            loader.hideLoader();
            return notification.showNotification(e.message);
        }

    }

    return (
        <div className="auth show">
            <div className="auth__content show">
                <h2 className="auth__header">{isLogin ? (resetPass || isResetPassSended ? 'Resetează parola' : 'Autorizare') : 'Registrare'}</h2>
                <form 
                    className="auth__form"
                    onSubmit={(e) => e.preventDefault}
                >
                    {!isLogin 
                    &&
                    !isSmsSended 
                    &&
                    <input
                        className="auth__form__input name"
                        placeholder="Numele"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                    }
                    
                    {!isSmsSended && !isResetPassSended &&
                    <input
                        className="auth__form__input"
                        placeholder="Numărul de telefon"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                    />
                    }
                    {!isSmsSended && !resetPass && !isResetPassSended &&
                    <div className="auth__form__password-wrapper">
                        <input
                            className="auth__form__input"
                            placeholder="Parola"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type={showPassword ? "text" : "password"}
                        />
                        <button
                            type="button"
                            className="auth__form__toggle-password"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? 
                            <svg className="auth__form__password__hide" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.9375 10L17.1531 6.1795M11.5 11.6875V7.75M3.0625 10L5.84012 6.1885M1.375 1C5.425 10 17.575 10 21.625 1" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                             
                            : 

                            <svg className="auth__form__password__show" width="24" height="14" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.75 8.25C5.25 -1.75 18.75 -1.75 23.25 8.25" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 13.25C11.5075 13.25 11.0199 13.153 10.5649 12.9645C10.11 12.7761 9.69657 12.4999 9.34835 12.1517C9.00013 11.8034 8.72391 11.39 8.53545 10.9351C8.347 10.4801 8.25 9.99246 8.25 9.5C8.25 9.00754 8.347 8.51991 8.53545 8.06494C8.72391 7.60997 9.00013 7.19657 9.34835 6.84835C9.69657 6.50013 10.11 6.22391 10.5649 6.03545C11.0199 5.847 11.5075 5.75 12 5.75C12.9946 5.75 13.9484 6.14509 14.6517 6.84835C15.3549 7.55161 15.75 8.50544 15.75 9.5C15.75 10.4946 15.3549 11.4484 14.6517 12.1517C13.9484 12.8549 12.9946 13.25 12 13.25Z" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            }
                        </button>
                    </div>}

                    <div className="auth__form__wrapper__policy">
                        {isLogin 
                            ?
                            !resetPass && !isResetPassSended &&
                            <div 
                                className="auth__form__forgot"
                                onClick={() => {
                                    setResetPass(true);
                                }}
                            >
                                Ai uitat parola?
                            </div>
                            :
                            !isSmsSended 
                            &&
                            <div className="auth__form__checbox__wrapper">
                                <input 
                                    type="checkbox" 
                                    className="auth__form__checkbox"
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                <div className="auth__form__policy">
                                    Accept 
                                    <a href="https://amoresushi.com/policy" target="blank" className="auth__form__policy-link">politica de confidelitate</a>
                                </div>
                            </div>
                        }
                    </div>

                    {isResetPassSended && 
                        <h2 className="reset__pass">
                            Introduceți parola nouă
                        </h2>
                    }

                    {
                        isResetPassSended &&
                        <div className="auth__form__password-wrapper">
                            <input
                                className="auth__form__input"
                                placeholder="Parola"
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                type={showPassword ? "text" : "password"}
                            />
                            <button
                                type="button"
                                className="auth__form__toggle-password"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? 
                                <svg className="auth__form__password__hide" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9375 10L17.1531 6.1795M11.5 11.6875V7.75M3.0625 10L5.84012 6.1885M1.375 1C5.425 10 17.575 10 21.625 1" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                
                                : 

                                <svg className="auth__form__password__show" width="24" height="14" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 8.25C5.25 -1.75 18.75 -1.75 23.25 8.25" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 13.25C11.5075 13.25 11.0199 13.153 10.5649 12.9645C10.11 12.7761 9.69657 12.4999 9.34835 12.1517C9.00013 11.8034 8.72391 11.39 8.53545 10.9351C8.347 10.4801 8.25 9.99246 8.25 9.5C8.25 9.00754 8.347 8.51991 8.53545 8.06494C8.72391 7.60997 9.00013 7.19657 9.34835 6.84835C9.69657 6.50013 10.11 6.22391 10.5649 6.03545C11.0199 5.847 11.5075 5.75 12 5.75C12.9946 5.75 13.9484 6.14509 14.6517 6.84835C15.3549 7.55161 15.75 8.50544 15.75 9.5C15.75 10.4946 15.3549 11.4484 14.6517 12.1517C13.9484 12.8549 12.9946 13.25 12 13.25Z" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                }
                            </button>
                        </div>

                    }

                    {
                        isResetPassSended &&
                        <div className="auth__form__password-wrapper">
                            <input
                                className="auth__form__input"
                                placeholder="Verifică parola"
                                value={verifyPassword}
                                onChange={e => setVerifyPassword(e.target.value)}
                                type={showPassword ? "text" : "password"}
                            />
                            <button
                                type="button"
                                className="auth__form__toggle-password"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? 
                                <svg className="auth__form__password__hide" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9375 10L17.1531 6.1795M11.5 11.6875V7.75M3.0625 10L5.84012 6.1885M1.375 1C5.425 10 17.575 10 21.625 1" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                
                                : 

                                <svg className="auth__form__password__show" width="24" height="14" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 8.25C5.25 -1.75 18.75 -1.75 23.25 8.25" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 13.25C11.5075 13.25 11.0199 13.153 10.5649 12.9645C10.11 12.7761 9.69657 12.4999 9.34835 12.1517C9.00013 11.8034 8.72391 11.39 8.53545 10.9351C8.347 10.4801 8.25 9.99246 8.25 9.5C8.25 9.00754 8.347 8.51991 8.53545 8.06494C8.72391 7.60997 9.00013 7.19657 9.34835 6.84835C9.69657 6.50013 10.11 6.22391 10.5649 6.03545C11.0199 5.847 11.5075 5.75 12 5.75C12.9946 5.75 13.9484 6.14509 14.6517 6.84835C15.3549 7.55161 15.75 8.50544 15.75 9.5C15.75 10.4946 15.3549 11.4484 14.6517 12.1517C13.9484 12.8549 12.9946 13.25 12 13.25Z" stroke="#7B7B7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                }
                            </button>
                    </div>

                    }

                    {isResetPassSended && 
                        <h2 style={{marginTop: '5px'} } className="reset__pass">
                            Introduceți codul din sms
                        </h2>
                    }

                    {(isSmsSended || isResetPassSended)
                    
                    &&

                    <div className="auth__form__wrapper__smscode">
                        <input 
                            type="text" 
                            className="auth__form__input sms" 
                            placeholder="Introdu codul din sms"
                            value={code}
                            onChange={e => setCode(e.target.value)}
                        />
                        <SmsResend name={name} phone={phone} password={password} isReset={isResetPassSended}/>
                    </div>

                    
                    }
                    
                    <button 
                        className="auth__btn"
                        onClick={e => {
                            e.preventDefault()
                            if (isLogin) {
                                if (resetPass) {
                                    sendResetSms();
                                }
                                if (isResetPassSended) {
                                    resetPassword();
                                }
                                if (!resetPass && !isResetPassSended) {
                                    authorization();
                                }
                            }

                            if (isSmsSended && !isLogin) {
                                authorization()
                            } 
                            if (!isSmsSended && !isLogin) {
                                sendSms();
                            }
                        }}
                    >
                        {isLogin ? (resetPass ? 'Primește sms code' : (isResetPassSended ? 'Verifică codul' : 'Log in')) : isSmsSended ? 'Înregistreazăte' : `Confirmă nr. de telefon`}
                    </button>
                    {isLogin 
                    ?
                    !resetPass && !isSmsSended && !isResetPassSended &&
                    <div className="auth__or__reg">
                        <NavLink 
                            to={REGISTRATION_ROUTE}
                        >
                            Nu ai cont?
                        </NavLink>
                    </div>
                    :
                    <div className="auth__or__reg">
                        <NavLink 
                            to={LOGIN_ROUTE}
                            onClick={() => {
                                setIsSmsSended(false);
                            }}
                        >
                            Deja ai cont?
                        </NavLink>
                    </div>}
                </form>
            </div>
        </div>
    );
});

export default Auth;