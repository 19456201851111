import { observer } from "mobx-react-lite";
import AppRouter from "./components/AppRouter/AppRouter";
import NavBar from "./components/NavBar/NavBar";
import { useContext, useEffect, useState } from "react";
import { Context } from "./index";
import { check, logout } from "./http/userApi";
import { fetchBasket } from "./http/basketApi";
import { fetchCategories, fetchProducts, fetchTypes } from "./http/productApi";
import Footer from "./components/Footer/Footer";
import Notification from "./components/Notification/Notification";
import { fetchSliderPages } from "./http/sliderApi";
import Loader from "./components/Loader/Loader";

const { BrowserRouter } = require("react-router-dom");


const App = observer(() => {
  const {user, product, slider, notification, loader} = useContext(Context)
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {

    loader.showLoader(false)

    const getUserData = () => {
      check().then(data => {
        if (!data.userData) {
          return console.log('Unauthorized!')
        }
        user.setUser(data.userData)
        user.setIsAuth(true)
        user.setRole(data.userData.role)
        return user.user.id
      })
      .then(data => {
        if (!data) {
          return
        }
        return fetchBasket(data)
      })
      .then(basketData => {
        if (!basketData) {
          return
        }
        user.setBasket(basketData);
      })
      .catch( async (e) => {
        console.log(e.message)
        const data = await logout()
        notification.showNotification(e.message)
      })
    }

    const getSliderData = async () => {
      await fetchSliderPages().then(data => {
        slider.setSliderPages(data)
      })
    }

    const getAllData = () => {
      Promise.all([
        fetchCategories().then(data => product.setCategories(data)),
        fetchTypes().then(data => product.setTypes(data)),
        fetchProducts(null, null).then(data => product.setProducts(data))
      ])
      .then(() => getUserData())
      .then(() => getSliderData())
      .catch(error => console.error("Error fetching data:", error))
      .finally(() => {
        setLoading(false)
        loader.hideLoader()
      })
    }

    getAllData()
  }, [product, user]);

  if (loading) {
    return <Loader/>
  }

  return (
    <div className="app">
      <BrowserRouter>
        <NavBar />
        <AppRouter />
        <Footer/>
      </BrowserRouter>
      <Loader/>
      <Notification />
    </div>
  );
});

export default App;