import {$authHost, $host} from "./index"


export const fetchBasket = async (id) => {
    const {data} = await $authHost.get(`api/basket/${id}`, { withCredentials: true })
    return data
}

export const addBasketProduct = async (basketProduct) => {
    const {data} = await $authHost.post('api/basket_product', basketProduct, { withCredentials: true })
    return data
}

export const removeBasketProduct = async (id) => {
    const {data} = await $authHost.delete(`api/basket_product/${id}`, { withCredentials: true })
    return data
}

export const removeAllBasketProducts = async (basketId) => {
    const {data} = await $authHost.delete(`api/basket_product/clear/${basketId}`, { withCredentials: true })
    return data
}

export const decrementQuantity = async (id) => {
    const {data} = await $authHost.patch(`api/basket_product/decr/${id}`, {}, { withCredentials: true })
    return data
}

export const incrementQuantity = async (id) => {
    const {data} = await $authHost.patch(`api/basket_product/incr/${id}`, {}, { withCredentials: true })
    return data
}

