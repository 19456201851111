import { useContext, useState } from 'react';
import './Dropdown.scss'
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';

const Dropdown = observer(({ options, optionName, selectedOption, setSelectedOption, isDisabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {notification} = useContext(Context)
  
  const toggleDropdown = () => {
      if (isDisabled) {
          notification.showNotification('Alegeți categoria!')
      } else {
          setIsOpen(!isOpen);
      }
  };

  const handleOptionClick = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
  };

  return (
      <div className="dropdown">
          <button 
              className="dropdown__toggle" 
              onClick={toggleDropdown}
          >
              {selectedOption ? selectedOption.name : optionName}
          </button>
          {isOpen && (
              <ul className="dropdown__menu">
                  {options.map((option, index) => (
                      <li key={index} onClick={() => handleOptionClick(option)}>
                          {option.name}
                      </li>
                  ))}
              </ul>
          )}
      </div>
  );
});

export default Dropdown;