import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { sendForm } from "../../http/formApi";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { createOrder } from "../../http/orderApi";
import Dropdown from "../Dropdown/Dropdown";
import './BuyForm.scss'
import './BuyFormMedia.scss'

const BuyForm = observer(({basket, totalPrice, isVisible, setIsVisible}) => {
    const {user, notification, loader} = useContext(Context)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [purchasedProducts, setPurchasedProducts] = useState('')
    const [productsIds, setProductsIds] = useState([])
    const [address, setAddress] = useState('')
    const [deliveryMethod, setDeliveryMethod] = useState(null)

    useEffect(() => {
        const products = basket.map((item, i) => `${item.name} x${item.quantity} ${+item.price * +item.quantity} lei${i === basket.length - 1 ? '' : ','}`).join('\n');
        const userPhone = user.user.phone;
        const userName = user.user.name;
        setName(userName)
        setPhone(userPhone)
        setPurchasedProducts(products);
        setProductsIds(basket.map(item => item.id))
    }, [basket]);

    const sendData = async () => {
        loader.showLoader();
        if (!name) {
            return notification.showNotification('Name error')
        }
        if (!phone) {
            return notification.showNotification('Phone error')
        }
        if (!purchasedProducts) {
            return notification.showNotification('Products error')
        }
        if (purchasedProducts.length > 999) {
            return notification.showNotification('Prea multe produse!')
        }

        if (!totalPrice) {
            return notification.showNotification('TotalPrice error')
        }
        if (!deliveryMethod) {
            return notification.showNotification('Alegeți modalitatea de livrare!')
        }
        if (!address) {
            return notification.showNotification('Întroduceți adresa!')
        }
        if (address.length > 60) {
            return notification.showNotification('Adresa e prea lungă!')
        }
        if (productsIds.length < 1) {
            return notification.showNotification('ProductIds length is 0')
        }

        const formData = new FormData()
        formData.append('name', name)
        formData.append('phone', phone)
        formData.append('purchasedProducts', purchasedProducts)
        formData.append('totalPrice', totalPrice)
        formData.append('delivery', deliveryMethod)
        formData.append('address', address)
        await createOrder({userName: name, userPhone: phone, orderProducts: purchasedProducts, orderSum: totalPrice, userId: user.user.id, delivery: deliveryMethod, address, productsIds})
        .then(data => {
            notification.showNotification(data.message)
            if (data.message === 'Succes!') {
                sendForm(formData)
            }
        })
        .then(data => {
            setDeliveryMethod(null)
            setAddress('')
            setIsVisible(false)
        })
        .catch(error => console.error("Error adding product:", error.response.data))
        .finally(() => {
            setTimeout(() => {
                loader.hideLoader();
            }, 300)
        })
    }

    const handleChange = (event) => {
        if (event.target.value === "Livrare la domiciliu") {
            setDeliveryMethod(1);
            setAddress('')
        }

        if (event.target.value === "Pe loc") {
            setDeliveryMethod(2);
            setAddress('Pe loc')
        }

        if (event.target.value === "La pachet") {
            setDeliveryMethod(3);
            setAddress('La pachet')
        }
    };

    

    return (
        <div
            className={isVisible ? "buy__form__modal show" : "buy__form__modal"}
        >
            <div 
                className={isVisible ? "buy__form__modal__content show" : "buy__form__modal__content"}
                onMouseDown={e => e.stopPropagation()}
            >
                <div 
                    className="buy__form__modal__close"
                    onClick={() => {
                        setIsVisible(false)
                        setDeliveryMethod(null)
                        setAddress('')
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3002 5.71022C18.2077 5.61752 18.0978 5.54397 17.9768 5.49379C17.8559 5.44361 17.7262 5.41778 17.5952 5.41778C17.4643 5.41778 17.3346 5.44361 17.2136 5.49379C17.0926 5.54397 16.9827 5.61752 16.8902 5.71022L12.0002 10.5902L7.11022 5.70022C7.01764 5.60764 6.90773 5.5342 6.78677 5.4841C6.6658 5.43399 6.53615 5.4082 6.40522 5.4082C6.27429 5.4082 6.14464 5.43399 6.02368 5.4841C5.90272 5.5342 5.79281 5.60764 5.70022 5.70022C5.60764 5.79281 5.5342 5.90272 5.4841 6.02368C5.43399 6.14464 5.4082 6.27429 5.4082 6.40522C5.4082 6.53615 5.43399 6.6658 5.4841 6.78677C5.5342 6.90773 5.60764 7.01764 5.70022 7.11022L10.5902 12.0002L5.70022 16.8902C5.60764 16.9828 5.5342 17.0927 5.4841 17.2137C5.43399 17.3346 5.4082 17.4643 5.4082 17.5952C5.4082 17.7262 5.43399 17.8558 5.4841 17.9768C5.5342 18.0977 5.60764 18.2076 5.70022 18.3002C5.79281 18.3928 5.90272 18.4662 6.02368 18.5163C6.14464 18.5665 6.27429 18.5922 6.40522 18.5922C6.53615 18.5922 6.6658 18.5665 6.78677 18.5163C6.90773 18.4662 7.01764 18.3928 7.11022 18.3002L12.0002 13.4102L16.8902 18.3002C16.9828 18.3928 17.0927 18.4662 17.2137 18.5163C17.3346 18.5665 17.4643 18.5922 17.5952 18.5922C17.7262 18.5922 17.8558 18.5665 17.9768 18.5163C18.0977 18.4662 18.2076 18.3928 18.3002 18.3002C18.3928 18.2076 18.4662 18.0977 18.5163 17.9768C18.5665 17.8558 18.5922 17.7262 18.5922 17.5952C18.5922 17.4643 18.5665 17.3346 18.5163 17.2137C18.4662 17.0927 18.3928 16.9828 18.3002 16.8902L13.4102 12.0002L18.3002 7.11022C18.6802 6.73022 18.6802 6.09022 18.3002 5.71022Z" fill="black"/>
                    </svg>

                </div>

                <h2>Comanda dvs:</h2>

                <div className="buy__form__modal__products">
                    {purchasedProducts}
                </div>

                <div className="buy__form__modal__total">
                    Prețul produselor: {totalPrice} Lei
                </div>

                <div className="buy__form__modal__divider"></div>

                <h3>
                    Alege modalitatea de livrare:
                </h3>

                <form 
                    className="buy__form__modal__form"
                    onSubmit={(e) => e.preventDefault}
                >
                    
                    <div>
                        <label>
                        <input
                            className="buy__form__modal__form__checkbox"
                            type="radio"
                            value="Livrare la domiciliu"
                            checked={deliveryMethod === 1}
                            onChange={handleChange}
                        />
                        Livrare la domiciliu
                        </label>
                    </div>
                    <div>
                        <label>
                        <input
                            className="buy__form__modal__form__checkbox"
                            type="radio"
                            value="Pe loc"
                            checked={deliveryMethod === 2}
                            onChange={handleChange}
                        />
                        Pe loc
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                className="buy__form__modal__form__checkbox"
                                type="radio"
                                value="La pachet"
                                checked={deliveryMethod === 3}
                                onChange={handleChange}
                            />
                            La pachet
                        </label>
                    </div>
                
                    {deliveryMethod === 1 
                    && 
                        <div>
                            <input
                            className="buy__form__modal__form__input"
                            placeholder="Adresa"
                            type="text"
                            value={address}
                            onChange={e => setAddress(e.target.value)}
                            />

                            <div className="buy__form__modal__delivery__text">
                                Livrare gratuită la comanda peste 200 lei.
                            </div>
                            
                            <div className="buy__form__modal__delivery__price">
                                Livrarea - 30 de lei.
                            </div>
                        </div>
                    }
                    
                </form>

                <div className="buy__form__modal__divider"></div>

                <div className="buy__form__modal__total__price">
                    {deliveryMethod === 1 && totalPrice < 200 ? 'Total: ' + (totalPrice + 30) : 'Total: ' + totalPrice} Lei
                </div>
                    
                <button 
                    className="buy__form__modal__btn"
                    onClick={() => sendData()}
                >
                    Comandă
                </button>
            </div>
        </div>
    );
});

export default BuyForm;