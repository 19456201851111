import { useContext, useEffect, useState } from "react";
import { Context } from "../../index";
import ProductItem from "../ProductItem/ProductItem";
import { useNavigate } from "react-router-dom";
import { CATEGORY_ROUTE } from "../../utils/consts";
import { observer } from "mobx-react-lite";

const CategoryItem = observer(({categoryId, title, category}) => {
    const {product, loader} = useContext(Context)
    const [products, setProducts] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        setProducts(product.products.filter((product) => {
            return product.categoryId === categoryId
        }))
    }, [product.products, categoryId])

    return (
        <div className="category__item">
                <div className="category__item__header__wrapper">
                    <h2 
                        className="category__item__header"
                        onClick={() => {
                            loader.showLoader(false);
                            setTimeout(() => {
                                navigate(CATEGORY_ROUTE + '/' + categoryId)
                                product.setSelectedCategory(category)
                            }, 500);
                        }} 
                    >
                        {title}
                    </h2>
                    
                    <h2
                        className="category__item__link" 
                        onClick={() => {
                            loader.showLoader(false);
                            setTimeout(() => {
                                
                                navigate(CATEGORY_ROUTE + '/' + categoryId)
                                product.setSelectedCategory(category)
                            }, 500);
                            
                        }} 
                    >
                        Mai multe...
                    </h2>
                </div>

                <div className="category__item__list">
                    {(product.products.filter((product) => {
                        return product.categoryId === categoryId
                    })).slice(0, 5).map((product) => (
                        <ProductItem key={product.id} productItem={product} />
                    ))}
                </div>

        </div> 
    );
});

export default CategoryItem;