import {$authHost, $host} from "./index"

export const createCategory = async (category) => {
    const {data} = await $authHost.post('api/category', category, { withCredentials: true })
    return data
}

export const fetchCategories = async () => {
    const {data} = await $host.get('api/category')
    return data
}

export const fetchOneCategory = async (id) => {
    const {data} = await $host.get(`api/category/${id}`)
    return data
}

export const editCategoryName = async (id, categoryName) => {
    const {data} = await $authHost.patch(`api/category/edit-name/${id}`, {categoryName}, { withCredentials: true })
    return data
}

export const createType = async (type) => {
    const {data} = await $authHost.post('api/type', type, { withCredentials: true })
    return data
}

export const fetchTypes = async () => {
    const {data} = await $host.get(`api/type`)
    return data
}

export const editTypeName = async (id, typeName) => {
    const {data} = await $authHost.patch(`api/type/edit-name/${id}`, {typeName}, { withCredentials: true })
    return data
}

export const editProductType = async (id, type) => {
    const {data} = await $authHost.patch(`api/product/change-type/${id}`, {typeId: type.id}, { withCredentials: true })
    return data
}

export const createProduct = async (product) => {
    const {data} = await $authHost.post('api/product', product, { withCredentials: true })
    return data
}

export const fetchProducts = async (categoryId, typeId) => {
    const {data} = await $host.get('api/product',  {params: {
        categoryId, typeId
    }})
    return data
}

export const fetchOneProduct = async (id) => {
    const {data} = await $host.get(`api/product/${id}`)
    return data
}

export const editProductName = async (id, productName) =>  {
    const {data} = await $authHost.patch(`api/product/change-name/${id}`, {productName}, { withCredentials: true })
    return data
}

export const editProductPrice = async (id, productPrice, productOldPrice) => {
    const {data} = await $authHost.patch(`api/product/change-price/${id}`, {productPrice, productOldPrice}, { withCredentials: true })
    return data
}

export const editProductInfo = async (id, title, description) => {
    const {data} = await $authHost.patch(`api/product/change-info/${id}`, {title, description}, { withCredentials: true })
    return data
}

export const editProductImg = async (id, img) => {
    const {data} = await $authHost.put(`api/product/change-image/${id}`, img, { withCredentials: true })
    return data
}

export const createProductInfo = async (productId, title, description) => {
    const {data} = await $authHost.post(`api/product/product-info/${productId}`, {title, description}, { withCredentials: true })
    return data
}

export const removeProductInfo = async (id) => {
    const {data} = await $authHost.delete(`api/product/product-info/${id}`, { withCredentials: true })
    return data
}

export const removeOneCategory = async (id) => {
    const {data} = await $authHost.delete(`api/category/delete-category/${id}`, { withCredentials: true })
    return data
}

export const removeOneType = async (id) => {
    const {data} = await $authHost.delete(`api/type/delete-type/${id}`, { withCredentials: true })
    return data
}

export const removeOneProduct = async (id) => {
    const {data} = await $authHost.delete(`api/product/${id}`, { withCredentials: true })
    return data
}


