import './Basket.scss'
import BasketList from "../../components/BasketList/BasketList";
import { observer } from "mobx-react-lite";
import BuyForm from '../../components/modals/BuyForm';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../index';
import { fetchBasket, removeAllBasketProducts } from '../../http/basketApi';
import ToShop from '../../components/ToShop/ToShop';
import { fetchProducts } from '../../http/productApi';
import './BasketMedia.scss'
import Loader from '../../components/Loader/Loader';

const Basket = observer(() => {
  const {user, product, notification, loader} = useContext(Context);
  const [buyVisible, setBuyVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [basketItems, setBasketItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        loader.showLoader(false);
        const data = await fetchBasket(user.user.id)
        user.setBasket(data);
        const newBasketItems = user.basket.basket_products.map(item => {
          const productItem = product.products.find(prodItem => prodItem.id === item.productId);
          return {...productItem, quantity: item.quantity, basketProductId: item.id};
        });
        setTotalPrice(0)
        newBasketItems.forEach(item => setTotalPrice(prev => prev + item.price * item.quantity))
        setBasketItems(newBasketItems);
      } catch (e) {
        console.error(e);
      }
    }

    const getProductsData = async () => {
        fetchProducts(null, null).then(data => product.setProducts(data))
        .catch(error => console.error("Error fetching data:", error))
        .finally(data => setLoading(false))
    }

    setLoading(true);
    setTimeout(() => {
      getProductsData()
      .then(() => {
        fetchData();
      })
    }, 500);
  }, [product]);
  
  if (loading) {
    return <Loader/>
  }

  return (
      <div className='basket'>
        <div id="container">
        <ToShop></ToShop>
            <div className="basket__header">
              Coș
            </div>
          <div className="basket__wrapper">

            <BasketList 
              products={user.products} 
              basketItems={basketItems} 
              setBasketItems={setBasketItems} 
              setTotalPrice={setTotalPrice}>
            </BasketList>

            {basketItems.length > 0 && 
              <div className="basket__buy__wrapper">
              <h2 className="basket__buy__header">
                În total
              </h2>
              <div className="basket__buy__divider"></div>
              <div className="basket__buy__wrapper__child">
                <div className="basket__totalprice">{totalPrice} Lei</div>
                <button 
                  className='basket__buy__button' 
                  onClick={() => {

                    if (!basketItems || basketItems.length < 1) {
                      return notification.showNotification('Coșul este pustiu!');
                    }

                    return setBuyVisible(true);

                  }}
                >
                  Comandă
                </button>
              </div>
              <div 
                className="basket__list__clear" 
                onClick={() => {
                  loader.showLoader(false);
                  removeAllBasketProducts(user.basket.id)
                    .then(data => notification.showNotification(data.message))
                    .then(() => setBasketItems([]))
                    .finally(() => {
                      setTimeout(() => {
                        loader.hideLoader()
                      }, 300)
                    })
                }}
              >
                Șterge coșul
              </div>
            </div>
            }
          </div>

          <BuyForm 
            basket={basketItems} 
            totalPrice={totalPrice} 
            isVisible={buyVisible}
            setIsVisible={setBuyVisible}
          />
        </div>
      </div>
  );
});

export default Basket;