import React, { useContext, useEffect } from 'react';
import { Context } from '../../index';

const PrivacyPolicy = () => {
    const {loader} = useContext(Context);

    useEffect(() => {
        loader.showLoader(false);
        setTimeout(() => {
            loader.hideLoader();
        }, 500)
    }, [])

    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', color: '#fff', paddingTop: '100px', paddingBottom: '350px' }}>
            <h1>Politica de confidențialitate</h1>

            <h2>1. Introducere</h2>
            <p>
                Această Politică de confidențialitate (denumită în continuare „Politica”) descrie modul în care compania Amore Sushi
                (denumită în continuare „Compania”, „noi”, „ne” sau „nostru”) prelucrează datele personale ale utilizatorilor site-ului
                nostru web (denumit în continuare „Site-ul web”). Respectăm confidențialitatea dumneavoastră și ne angajăm să protejăm
                datele personale. Înregistrându-vă pe Site-ul nostru sau utilizându-l, sunteți de acord cu termenii acestei Politici.
            </p>

            <h2>2. Ce date colectăm</h2>
            <p>
                Colectăm și prelucrăm următoarele tipuri de date personale:
                <ul>
                <li>Date de contact: numărul de telefon.</li>
                <li>Informații despre cont: numele de utilizator, parola.</li>
                <li>Adresa de livrare: pentru procesarea și livrarea comenzilor.</li>
                <li>Adresa IP: pentru a asigura securitatea și a îmbunătăți funcționarea Site-ului.</li>
                <li>Fișiere cookie: pentru a stoca token-urile JWT necesare pentru autentificare.</li>
                <li>Istoricul comenzilor: informații despre comenzile anterioare pentru statistici și analize.</li>
                </ul>
                <strong>Nu colectăm</strong> date despre cardurile bancare sau alte informații de plată, precum și adrese de email ale utilizatorilor.
            </p>

            <h2>3. Scopurile colectării datelor</h2>
            <p>
                Colectăm datele dvs. în următoarele scopuri:
                <ul>
                <li>Procesarea și livrarea comenzilor.</li>
                <li>Asigurarea securității contului dvs. și prevenirea accesului neautorizat.</li>
                <li>Colectarea de statistici despre site și analiza comenzilor.</li>
                <li>Îmbunătățirea calității site-ului și personalizarea experienței utilizatorilor.</li>
                </ul>
            </p>

            <h2>4. Cum folosim datele dumneavoastră</h2>
            <p>
                Datele personale sunt utilizate în următoarele scopuri:
                <ul>
                <li>Înregistrarea și autentificarea pe Site.</li>
                <li>Procesarea și livrarea comenzilor la adresa indicată.</li>
                <li>Contactarea utilizatorului pentru clarificarea detaliilor comenzii, dacă este necesar.</li>
                <li>Colectarea de statistici despre comenzile plasate pentru analiză și îmbunătățirea serviciilor, precum și personalizarea ofertelor.</li>
                </ul>
            </p>

            <h2>5. Utilizarea numărului de telefon pentru verificare și comunicări prin SMS</h2>
            <p>
                <ul>
                <li>
                    <strong>Verificare prin SMS:</strong> La înregistrarea pe site, vi se va solicita să furnizați un număr de telefon.
                    Acest număr este folosit pentru a vă trimite un cod unic prin SMS, necesar pentru a verifica identitatea dvs. și
                    pentru a finaliza procesul de înregistrare.
                </li>
                <li>
                    <strong>Mesaje despre oferte speciale și reduceri:</strong> Utilizăm numărul dvs. de telefon pentru a vă trimite
                    notificări prin SMS despre oferte speciale, reduceri și promoții, dacă sunteți un utilizator înregistrat.
                    Puteți opta să nu mai primiți aceste mesaje urmând instrucțiunile din SMS.
                </li>
                <li>
                    <strong>Resetarea parolei:</strong> Dacă ați uitat parola, vi se poate trimite un cod prin SMS pentru a o reseta.
                </li>
                </ul>
            </p>

            <h2>6. Stocarea și protecția datelor</h2>
            <p>
                Datele dumneavoastră sunt stocate pe servere securizate într-o bază de date. Datele dumneavoastră (contul, istoricul
                comenzilor) vor fi stocate pe perioada utilizării serviciului nostru și pentru o perioadă rezonabilă după încetarea
                utilizării, pentru statistici și analiză.
                <br />
                Luăm toate măsurile necesare pentru a proteja datele dumneavoastră împotriva accesului neautorizat sau a scurgerilor de
                informații, utilizând tehnologii moderne de criptare și protocoale de securitate.
            </p>

            <h2>7. Transferul datelor către alte părți.</h2>
            <p>
                Nu transferăm datele dumneavoastră personale către alte persoane, cu excepția următoarelor cazuri:
                <ul>
                <li>Transferul datelor către parteneri pentru livrarea comenzilor (de exemplu, servicii de curierat).</li>
                <li>Respectarea cerințelor legale.</li>
                </ul>
            </p>

            <h2>8. Drepturile dumneavoastră</h2>
            <p>
                Aveți dreptul:
                <ul>
                <li>Să accesați datele personale pe care le deținem.</li>
                <li>Să corectați sau să ștergeți datele dumneavoastră.</li>
                <li>Să retrageți consimțământul pentru prelucrarea datelor (dacă a fost acordat).</li>
                <li>Să depuneți o plângere la autoritățile competente dacă considerați că drepturile dumneavoastră au fost încălcate.</li>
                </ul>
            </p>

            <h2>9. Utilizarea fișierelor cookie</h2>
            <p>
                Pe Site-ul nostru, folosim fișiere cookie <strong>doar</strong> pentru stocarea token-urilor JWT necesare pentru
                autentificare. Aceste cookie-uri nu sunt utilizate pentru urmărirea activității pe site sau în scopuri de marketing.
                Puteți dezactiva utilizarea fișierelor cookie din setările browserului, însă acest lucru poate afecta funcționarea
                anumitor funcții ale Site-ului.
            </p>

            <h2>10. Modificări ale Politicii de confidențialitate</h2>
            <p>
                Ne rezervăm dreptul de a modifica această Politică. Orice modificări vor fi anunțate prin actualizarea Politicii pe
                Site-ul nostru. Continuarea utilizării Site-ului după modificări implică acceptarea noii versiuni a Politicii.
            </p>

            <h2>11. Contact</h2>
            <p>
                Dacă aveți întrebări sau solicitări referitoare la prelucrarea datelor dumneavoastră personale, ne puteți contacta la
                următoarele numere de telefon:
                <br />
                <strong>Telefon:</strong> +373 60 516 691, +373 62 116 432.
            </p>
            </div>
    );
};

export default PrivacyPolicy;