import { observer } from 'mobx-react-lite';
import './OrderItem.scss'
import './OrderItemMedia.scss'
import { removeOrder } from '../../http/orderApi';
import { createUserOrder } from '../../http/userOrderApi';
import { increaseTotalSpend, incrementOrdersCount } from '../../http/userApi';
import { useNavigate } from 'react-router-dom';
import { USER_ROUTE } from '../../utils/consts';
import { format } from 'date-fns';
import ConfirmationalModal from '../modals/ConfirmationalModal/ConfirmationalModal';
import { useContext, useState } from 'react';
import { Context } from '../../index';

const OrderItem = observer(({userName, userPhone, orderProducts, orderSum, userId, orderId, updateOrder, createdAt, delivery, address}) => {
    const navigate = useNavigate()
    const {loader} = useContext(Context)
    const [isDelete, setIsDelete] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

    let deliveryMethod;

    if (delivery === 1) {
        deliveryMethod = 'Livrare'
    }

    if (delivery === 2) {
        deliveryMethod = 'Pe loc'
    }

    if (delivery === 3) {
        deliveryMethod = 'La pachet'
    }


    const formattedDate = format(new Date(createdAt), 'dd.MM.yyyy');
    const formattedTime = format(new Date(createdAt), 'HH:mm');


    const deleteOrder = async () => {
        try {
            loader.showLoader();
            await removeOrder(orderId);
        } catch (e) {
            console.log(e.message);
        } finally {
            loader.hideLoader();
            updateOrder();
        }
    };

    const handleCancel = () => {
        setIsConfirmModalVisible(false);
    };

    const done = async () => {
        try {
            loader.showLoader();
            await createUserOrder({userName, orderProducts, orderSum, orderId, userId, delivery, address})
            await incrementOrdersCount(userId)
            await increaseTotalSpend(userId, orderId)
            await removeOrder(orderId)
        } catch (e) {
            console.log(e.message)
        } finally {
            loader.hideLoader();
            updateOrder();
        }
    }

    return (
        <div className='order__item'>
            <div className="order__item__time">
                Comandat: {formattedTime} 
            </div>
            <div className="order__item__date">
                Data: {formattedDate}
            </div>
            <h3 
                className='order__item__name'
                onClick={() => navigate(USER_ROUTE + "/" + userId)}
            >
                {userName}
            </h3>
            <div className='order__item__phone'>Tel: 0{userPhone}</div>
            <div className='order__item__products'>{orderProducts}</div>
            <div className="order__item__delivery">{deliveryMethod}</div>
            <div className="order__item__address">Adresa: {address}</div>
            <div className="order__item__buy">
                <div className='order__item__sum'>Suma totală: {orderSum} lei</div>
                <button 
                    className='order__item__buy__btn' 
                    onClick={() => {
                        setIsDelete(false);
                        setIsConfirmModalVisible(true);
                    }}
                >
                    Terminat
                </button>
            </div>

            <div className="order__item__remove"
                onClick={() => {
                    setIsDelete(true);
                    setIsConfirmModalVisible(true);
                }}
            >
                <svg className="basket__item__remove__icon" fill="none" viewBox="6.5 2 29 29" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.2085 10.2083L28.7918 24.7917M14.2085 24.7917L28.7918 10.2083" stroke="#C82A2A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <ConfirmationalModal
                isVisible={isConfirmModalVisible} 
                setIsVisible={setIsConfirmModalVisible} 
                onConfirm={isDelete ? deleteOrder : done} 
                onCancel={handleCancel} 
                text={`${isDelete ? 'Doriți să anulați comanda?' : 'Confirmați dacă comanda este gata!'}`} 
            />
        </div>
    );
});

export default OrderItem;