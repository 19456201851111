import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { decrementQuantity, incrementQuantity, removeBasketProduct } from "../../http/basketApi";
import './BasketItem.scss'
import { useNavigate } from "react-router-dom";
import { PRODUCT_ROUTE } from "../../utils/consts";
import { Context } from "../../index";
import './BasketItemMedia.scss'

const BasketItem = observer(({product, setBasketItems, id, setTotalPrice}) => {
    const [quantity, setQuantity] = useState(product.quantity);
    const [isImgLoaded, setIsImgLoaded] = useState(false);
    const navigate = useNavigate();
    const {user, notification, loader} = useContext(Context);

    useEffect(() => {
        setBasketItems(prevItems => 
            prevItems.map(item => 
                item.basketProductId === id ? { ...item, quantity: quantity} : item
            )
        ); 
    }, [quantity])

    const removeProduct = async (id) => {
        try {
            const {message} = await removeBasketProduct(id);
            if (message === 'Produsul a fost șters din coș') {
                setBasketItems(prev => prev.filter(item => item.basketProductId !== id));
                user.setBasket({...user.basket, basket_products: user.basket.basket_products.filter(item => item.id !== id)});
                setTotalPrice(prev => prev - product.price * product.quantity);
            }
            notification.showNotification(message);
        } catch (e) {
            notification.showNotification(e.message);
        }
    };

    const quantityDecr = async (price) => {
        await loader.showLoader();
        const {message} = await decrementQuantity(id);
        notification.showNotification(message)
        setQuantity(prev => prev > 1 ? prev - 1 : prev)
        quantity > 1 && setTotalPrice(prev => prev - price)
        setTimeout(() => {
            loader.hideLoader();
        }, 300);
    };

    const quantityIncr = async (price) => {
        if (quantity < 10) {
            loader.showLoader();
            const {message} = await incrementQuantity(id);
            notification.showNotification(message)
            setQuantity(prev => prev + 1)
            quantity < 10 && setTotalPrice(prev => prev + price)
            setTimeout(() => {
                loader.hideLoader();
            }, 300);
        }
    };

    const handleImgLoad = () => {
        setIsImgLoaded(true);
    };

    return (
        <div className='basket__item'>
            <img 
                className={`basket__item__img ${isImgLoaded && 'active'}` }
                src={process.env.REACT_APP_API_URL + `server-static/${product.img}`} 
                alt="basket product" 
                onLoad={handleImgLoad}
                onClick={() => {
                    loader.showLoader(false);
                    setTimeout(() => {
                        navigate(PRODUCT_ROUTE + "/" + product.id)
                    }, 500);
                }}
            />
            <div 
                className="basket__item__header"
                onClick={() => {
                    loader.showLoader(false);
                    setTimeout(() => {
                        navigate(PRODUCT_ROUTE + "/" + product.id)
                    }, 500);
                }}
            >
                {product.name}
            </div>

            <div className="basket__item__quantity">
                <div className="basket__item__quantity__decr" onClick={() => quantityDecr(product.price)}><span>-</span></div>
                <div className="basket__item__quantity__value">{product.quantity}</div>
                <div className="basket__item__quantity__incr" onClick={() => quantityIncr(product.price)}>+</div>
            </div>

            <div className="basket__item__price">{product.price * product.quantity} lei</div>
    
            <div 
                className="basket__item__remove" 
                onClick={async () => {
                    loader.showLoader();
                    await removeProduct(id);
                    setTimeout(() => {
                        loader.hideLoader()
                    }, 300);
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24">
                    <path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21q.512.078 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48 48 0 0 0-3.478-.397m-12 .562q.51-.088 1.022-.165m0 0a48 48 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a52 52 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a49 49 0 0 0-7.5 0">
                    </path>
                </svg>
            </div>
        </div>
    );
});

export default BasketItem;
