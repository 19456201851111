import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../index";
import './CategoryBar.scss'
import './CategoryBarMedia.scss'
import { useNavigate } from "react-router-dom";
import { CATEGORY_ROUTE } from "../../utils/consts";


const CategoryBar = observer(() => {
    const {product, loader} = useContext(Context)
    const navigate = useNavigate()
    const [isHover, setIsHover] = useState(false)
    const [categories, setCategories] = useState([...product.categories])

    return (
        <div 
            className="category__bar"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <ul className="category__bar__content">
                {categories.map(category => 
                    <li className="category__bar__item"
                        onClick={() => {
                            loader.showLoader(false);
                            setTimeout(() => {
                                navigate(CATEGORY_ROUTE + '/' + category.id)
                                product.setSelectedCategory(category)
                            }, 500)
                        }} 
                        key={category.id}
                    >
                        {category.name}
                    </li>
                )}
            </ul>

        </div>
    );
});

export default CategoryBar;