import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN_ROUTE, PRODUCT_ROUTE, SHOP_ROUTE } from "../../utils/consts";
import { addBasketProduct } from "../../http/basketApi";
import { useContext, useState } from "react";
import { Context } from "../../index";
import './ProductItem.scss'
import './ProductItemMedia.scss'
import ChangeProductName from "../modals/ChangeProductName";
import ChangeProductPrice from "../modals/ChangeProductPrice";
import { removeOneProduct } from "../../http/productApi";
import { observer } from "mobx-react-lite";
import ConfirmationalModal from "../modals/ConfirmationalModal/ConfirmationalModal";

const ProductItem = observer(({productItem}) => {
    const location = useLocation();
    const navigate = useNavigate()
    const {user, product, notification, loader} = useContext(Context)
    const [currentProductName, setCurrentProductName] = useState(productItem.name)
    const [currentProductPrice, setCurrentProductPrice] = useState(productItem.price)
    const [currentProductOldPrice, setCurrentProductOldPrice] = useState(productItem.oldPrice)
    const [nameHover, setNameHover] = useState(false)
    const [priceHover, setPriceHover] = useState(false)
    const [imgHover, setImgHover] = useState(false)
    const [changeNameModalVisible, setChangeNameModalVisible] = useState(false)
    const [changePriceModalVisible, setChangePriceModalVisible] = useState(false)
    const [isConfirmDeleteProductVisible, setIsConfirmDeleteProductVisible] = useState(false)
    const [isImgLoaded, setIsImgLoaded] = useState(false);

    const [btnHover, setBtnHover] = useState(false);

    const id = productItem.id;
 
    const addProductInBasket = async (prod) => {
        const data = await addBasketProduct(prod)
        if (data.basketProduct) {
            user.setBasket({...user.basket, basket_products: [...user.basket.basket_products, data.basketProduct]})
        }
        notification.showNotification(data.message)
    }

    const deleteProduct = async () => {
        const data = await removeOneProduct(id)
        product.setProducts(product.products.filter(i => i.id !== id))
        notification.showNotification(data.message);
        if (location.path !== SHOP_ROUTE) {
            window.location.reload();
        }
    }

    const handleConfirm = async () => {
        await deleteProduct()
        setIsConfirmDeleteProductVisible(false)
    }

    const handleCancel = () => {
        setIsConfirmDeleteProductVisible(false)
    }

    const handleImgLoad = () => {
        setIsImgLoaded(true);
    }

    return (
        <div className="product">
            {user.user.role == 'ADMIN' 
            &&
            imgHover 
            && 
            <div 
                className="product__delete"
                onMouseEnter={() => user.user.role == 'ADMIN' && setImgHover(true)}
                onMouseLeave={() => user.user.role =='ADMIN' && setImgHover(false)}
                onClick={() => {
                    setIsConfirmDeleteProductVisible(true)
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="23px" viewBox="0 0 24 24">
                    <path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21q.512.078 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48 48 0 0 0-3.478-.397m-12 .562q.51-.088 1.022-.165m0 0a48 48 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a52 52 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a49 49 0 0 0-7.5 0">
                    </path>
                </svg>

            </div>
            }
            
            <div 
                className={`product__img-placeholder`}
                onClick={() => {
                    loader.showLoader(false);
                    setTimeout(() => {
                        navigate(PRODUCT_ROUTE + "/" + productItem.id)
                      }, 500);
                }}
                onMouseEnter={() => user.user.role == 'ADMIN' && setImgHover(true)}
                onMouseLeave={() => user.user.role =='ADMIN' && setImgHover(false)}
            >
                <img
                src={process.env.REACT_APP_API_URL + `server-static/${productItem.img}`} 
                alt="product"
                className={`product__img $ ${isImgLoaded && 'active'}`}
                onClick={() => {
                    loader.showLoader(false);
                    setTimeout(() => {
                        navigate(PRODUCT_ROUTE + "/" + productItem.id)
                      }, 500);
                }}
                onLoad={handleImgLoad}
                onMouseEnter={() => user.user.role == 'ADMIN' && setImgHover(true)}
                onMouseLeave={() => user.user.role =='ADMIN' && setImgHover(false)}
                />
            </div>

            <div 
                className="product__name"
                onMouseEnter={() => user.user.role == 'ADMIN' && setNameHover(true)}
                onMouseLeave={() => user.user.role =='ADMIN' && setNameHover(false)}
            >
                {currentProductName}
                {nameHover 
                && 
                <span
                    onClick={(e) => {
                        setChangeNameModalVisible(true)
                    }}
                >

                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 15.0129L8.413 14.9979L18.045 5.4579C18.423 5.0799 18.631 4.5779 18.631 4.0439C18.631 3.5099 18.423 3.0079 18.045 2.6299L16.459 1.0439C15.703 0.287902 14.384 0.291902 13.634 1.0409L4 10.5829V15.0129ZM15.045 2.4579L16.634 4.0409L15.037 5.6229L13.451 4.0379L15.045 2.4579ZM6 11.4169L12.03 5.4439L13.616 7.0299L7.587 13.0009L6 13.0059V11.4169Z"/>
                        <path d="M2 19H16C17.103 19 18 18.103 18 17V8.332L16 10.332V17H5.158C5.132 17 5.105 17.01 5.079 17.01C5.046 17.01 5.013 17.001 4.979 17H2V3H8.847L10.847 1H2C0.897 1 0 1.897 0 3V17C0 18.103 0.897 19 2 19Z"/>
                    </svg>

                </span>}

            </div>

            <ChangeProductName 
                isVisible={changeNameModalVisible} 
                setIsVisible={setChangeNameModalVisible} 
                productId={productItem.id}
                setCurrentProductName={setCurrentProductName}
            />

            <div 
                className="product__buy__wrapper"
                onMouseEnter={() => user.user.role == 'ADMIN' && setPriceHover(true)}
                onMouseLeave={() => user.user.role == 'ADMIN' && setPriceHover(false)}
            >

                <div className="product__price-old">
                    { currentProductOldPrice == 0 ? '' : `${currentProductOldPrice} lei` } 
                </div>

                <div 
                    className="product__price"
                    onMouseEnter={() => user.user.role == 'ADMIN' && setPriceHover(true)}
                    onMouseLeave={() => user.user.role == 'ADMIN' && setPriceHover(false)}
                >
                    {currentProductPrice} lei
                    
                    <span
                        onClick={() => user.user.role == 'ADMIN' && setChangePriceModalVisible(true)}
                    >
                        {priceHover 
                        && 
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 15.0129L8.413 14.9979L18.045 5.4579C18.423 5.0799 18.631 4.5779 18.631 4.0439C18.631 3.5099 18.423 3.0079 18.045 2.6299L16.459 1.0439C15.703 0.287902 14.384 0.291902 13.634 1.0409L4 10.5829V15.0129ZM15.045 2.4579L16.634 4.0409L15.037 5.6229L13.451 4.0379L15.045 2.4579ZM6 11.4169L12.03 5.4439L13.616 7.0299L7.587 13.0009L6 13.0059V11.4169Z"/>
                            <path d="M2 19H16C17.103 19 18 18.103 18 17V8.332L16 10.332V17H5.158C5.132 17 5.105 17.01 5.079 17.01C5.046 17.01 5.013 17.001 4.979 17H2V3H8.847L10.847 1H2C0.897 1 0 1.897 0 3V17C0 18.103 0.897 19 2 19Z"/>
                        </svg>
                        }
                    </span>
                </div>

                <ChangeProductPrice 
                    isVisible={changePriceModalVisible} 
                    setIsVisible={setChangePriceModalVisible}
                    productId={productItem.id}
                    setCurrentProductPrice={setCurrentProductPrice}
                    setCurrentProductOldPrice={setCurrentProductOldPrice}
                />

                <button 
                    className={btnHover ? "product__buy__button" : "product__buy__button active"}
                    onMouseEnter={(e) => {
                        setBtnHover(true)
                    }}
                    onMouseLeave={(e) => {
                        setBtnHover(false)
                    }}
                    onClick={() => {
                        const logged = () => {
                            loader.showLoader();
                            setTimeout(() => {
                                addProductInBasket({
                                    basketId: user.basket.id,
                                    productId: productItem.id
                                })
                                setTimeout(() => {
                                    loader.hideLoader();
                                }, 250)
                            }, 250)
                        }
                        const unlogged = () => {
                            loader.showLoader(false);
                            setTimeout(() => {
                                navigate(LOGIN_ROUTE)
                            }, 500)
                        }
                        user.isAuth 
                        ?
                        logged()
                        :
                        unlogged()
                    }}
                >
                    <div className={btnHover ? "product__buy__button__wrapper active" : "product__buy__button__wrapper"}>
                        <div className="product__buy__button__text">
                            în coș
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="23px" viewBox="0 0 16 16">
                            <path fill="white" fillRule="evenodd" d="M12 4.5h1.307a2.193 2.193 0 0 1 2.109 2.795l-1.294 4.53A3 3 0 0 1 11.237 14H4.763a3 3 0 0 1-2.885-2.176L.584 7.295A2.193 2.193 0 0 1 2.693 4.5H4V3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm-6.5 0V3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1.5zm5.737 8H4.763a1.5 1.5 0 0 1-1.442-1.088L2.027 6.883A.693.693 0 0 1 2.693 6h10.614a.693.693 0 0 1 .666.883l-1.294 4.53a1.5 1.5 0 0 1-1.442 1.087m-4.59-1.265a.75.75 0 0 1-.882-.588l-.5-2.5a.75.75 0 0 1 1.47-.294l.5 2.5a.75.75 0 0 1-.588.882m4.088-3.088a.75.75 0 1 0-1.47-.294l-.5 2.5a.75.75 0 1 0 1.47.294z" clipRule="evenodd">
                            </path>
                        </svg>
                    </div>
                    
                </button>
            </div>

            <ConfirmationalModal 
                isVisible={isConfirmDeleteProductVisible} 
                setIsVisible={setIsConfirmDeleteProductVisible}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                text={'Doriți să ștergeți produsul?'}
            />
        </div>
    );
});

export default ProductItem;
