import { useContext, useEffect, useState } from 'react';
import { Context } from '../../index';
import { fetchOrders } from '../../http/orderApi';
import { observer } from 'mobx-react-lite';
import './Statistic.scss'
import './StatisticMedia.scss'
import { fetchUsers } from '../../http/userApi';
import UserItem from '../../components/UserItem/UserItem';
import { parseISO, subMonths, isAfter, set } from 'date-fns';

const Statistic = observer(() => {
    const {statistic, loader} = useContext(Context);
    const [users, setUsers] = useState([]);
    const [statisticKey, setStatisticKey] = useState(0);
    const [lastMonth, setLastMonth] = useState(false);
    const [isSortedByOrders, setIsSortedByOrders] = useState(false);
    const [isSortedByOrdersUp, setIsSortedByOrdersUp] = useState(true);
    const [isSortedBySpend, setIsSortedBySpend] = useState(false);
    const [isSortedBySpendUp, setIsSortedBySpendUp] = useState(true);
    const [isSortedByDate, setIsSortedByDate] = useState(false);
    const [isSortedByDateUp, setIsSortedByDateUp] = useState(false);

    const updateStatistic = () => {
        setStatisticKey(prev => prev + 1);
    };

    const updateUsersStatisticsAndFilter = () => {
        const oneMonthAgo = subMonths(new Date(), 1);
    
        const updatedUsers = users.map(user => {
            const purchasesLastMonth = user.order.filter(purchase =>
                isAfter(parseISO(purchase.createdAt), oneMonthAgo)
            );
    
            const ordersCount = purchasesLastMonth.length;
            const totalSpend = purchasesLastMonth.reduce((total, purchase) => total + purchase.orderSum, 0);
    
            return {
                ...user,
                ordersCount,
                totalSpend
            };
        });
    
        const filteredUsers = updatedUsers.filter(user => user.ordersCount > 0);
    
        setUsers(filteredUsers);
        setLastMonth(true);
    };

    const sortUsersByTotalSpend = () => {
        let sortedUsers;
        if (isSortedBySpendUp) {
            sortedUsers = [...users].sort((a, b) => b.totalSpend - a.totalSpend);
            setIsSortedBySpendUp(false)
        } else {
            sortedUsers = [...users].sort((a, b) => a.totalSpend - b.totalSpend);
            setIsSortedBySpendUp(true)
        }
        setUsers(sortedUsers);
        setIsSortedBySpend(true);
        setIsSortedByOrders(false);
        setIsSortedByOrdersUp(true);
        setIsSortedByDate(false);
        setIsSortedByDateUp(false);
    };

    const sortUsersByOrdersCount = () => {
        let sortedUsers;
        if (isSortedByOrdersUp) {
            sortedUsers = [...users].sort((a, b) => b.ordersCount - a.ordersCount);
            setIsSortedByOrdersUp(false)
        } else {
            sortedUsers = [...users].sort((a, b) => a.ordersCount - b.ordersCount);
            setIsSortedByOrdersUp(true)
        }
        setUsers(sortedUsers);
        setIsSortedByOrders(true);
        setIsSortedBySpend(false);
        setIsSortedBySpendUp(true);
        setIsSortedByDate(false);
        setIsSortedByDateUp(false);
    };

    const sortUsersByRegistrationDate = () => {
        const sortedUsers = [...users].sort((a, b) =>
            isSortedByDateUp
                ? new Date(b.createdAt) - new Date(a.createdAt)
                : new Date(a.createdAt) - new Date(b.createdAt)
        );
        setIsSortedByOrders(false);
        setIsSortedByOrdersUp(true);
        setIsSortedBySpend(false);
        setIsSortedBySpendUp(true);
        setIsSortedByDateUp(!isSortedByDateUp);
        setIsSortedByDate(true);
        setUsers(sortedUsers);
    };

    const setDefault = () => {
        setIsSortedByOrders(false);
        setIsSortedByOrdersUp(true);
        setIsSortedBySpend(false);
        setIsSortedBySpendUp(true);
        setIsSortedByDate(false);
        setIsSortedByDateUp(false);
        fetchData();
    }

    const fetchData = async () => {
        try {
            const usersList = await fetchUsers()
            statistic.setUsers(usersList)
            setUsers(statistic.users)
            setLastMonth(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchData()
        loader.hideLoader();
    }, [statisticKey])


    return (
        <div className='statistic'>
            <div id="container">

                <h2 className="statistic__header">
                        Utilizatori: {users.length}
                </h2>

                <div className="statistic__menu">
                    <h2 
                        className='statistic__sort__reset'
                        onClick={() => setDefault()}
                    >
                        resetează
                    </h2>
                    
                    <h2 
                        className='statistic__last__month'
                        onClick={() => lastMonth ? setDefault() :  updateUsersStatisticsAndFilter()}
                    >
                        {lastMonth ? 'de tot timpul' : 'ultima lună'}
                    </h2>

                    
                    <h2 
                        className='statistic__sort__orders'
                        onClick={() => sortUsersByOrdersCount()}
                    >
                        comenzi  
                        {isSortedByOrders
                            ?
                            isSortedByOrdersUp
                                ?
                                <div className="statistic__sort__arrow">
                                    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 0L0 6H8L4 0Z" fill="#C2C2C2"/>
                                    </svg>
                                </div>
                                :
                                <div className="statistic__sort__arrow-reverse">
                                
                                    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 0L0 6H8L4 0Z" fill="#C2C2C2"/>
                                    </svg>
                            
                                </div>
                            :
                            ''
                        }
                    </h2>
                    

                    <h2 
                        className='statistic__sort__spend'
                        onClick={() => sortUsersByTotalSpend()}
                    >
                        cheltuit
                        {isSortedBySpend
                            ?
                            isSortedBySpendUp
                                ?
                                <div className="statistic__sort__arrow">
                                    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 0L0 6H8L4 0Z" fill="#C2C2C2"/>
                                    </svg>
                                </div>
                                :
                                <div className="statistic__sort__arrow-reverse">
                                
                                    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 0L0 6H8L4 0Z" fill="#C2C2C2"/>
                                    </svg>
                            
                                </div>
                            :
                            ''
                        }
                        
                    </h2>

                    <h2 
                        className='statistic__sort__date'
                        onClick={() => sortUsersByRegistrationDate()}
                    >
                        înregistrare
                        {isSortedByDate
                            ?
                            isSortedByDateUp
                                ?
                                <div className="statistic__sort__arrow">
                                    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 0L0 6H8L4 0Z" fill="#C2C2C2"/>
                                    </svg>
                                </div>
                                :
                                <div className="statistic__sort__arrow-reverse">
                                
                                    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 0L0 6H8L4 0Z" fill="#C2C2C2"/>
                                    </svg>
                            
                                </div>
                            :
                            ''
                        }
                        
                    </h2>

                </div>

                {users.map(user => (
                    <UserItem key={user.id} userData={user}/>
                ))}

            </div>
        </div>
    );
});

export default Statistic;