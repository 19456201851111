import { Context } from "../../index";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import './TypeBar.scss'
import './TypeBarMedia.scss'
import { removeOneType } from "../../http/productApi";
import ChangeTypeName from "../modals/ChangeTypeName/ChangeTypeName";
import ConfirmationalModal from "../modals/ConfirmationalModal/ConfirmationalModal";


const TypeBar = observer(({categoryId}) => {
    const {product, user, notification} = useContext(Context)
    const [activeType, setActiveType] = useState({})
    const [selectedType, setSelectedType] = useState({})
    const [editedType, setEditedType] = useState({})
    const [deleteTypeId, setDeleteTypeId] = useState(null)
    const [isConfirmDeleteTypeVisible, setIsConfirmDeleteTypeVisible] = useState(false)

    const filteredTypes = product.types.filter(type => type.categoryId == categoryId)

    const [allTypes, setAllTypes] = useState(filteredTypes)
    const [typeModalVisible, setTypeModalVisible] = useState(false)

    const deleteType = async (id) => {
        const data = await removeOneType(id)
        notification.showNotification(data.message)
        setAllTypes(prev => prev.filter(type => type.id !== id))
        product.setProducts(product.products.filter(i => i.typeId !== id))
        window.location.reload();
    } 

    useEffect(() => {
        product.setSelectedType({})
    }, [])

    const handleDeleteTypeConfirm = async () => {
        await deleteType(deleteTypeId)
        setIsConfirmDeleteTypeVisible(false)
        notification.showNotification('Tipul a fost șters')
        setDeleteTypeId(null)
    }

    const handleDeleteTypeCancel = async () => {
        setIsConfirmDeleteTypeVisible(false)
        setDeleteTypeId(null)
    }
    
    if (allTypes.length > 0) {
        return (
            <div className="type__bar">
                <div 
                    className="type__bar__item"
                    onClick={() => {
                        product.setSelectedType({})
                        setSelectedType({})
                    }}
                >
                    Toate
                </div>
                {allTypes.map(type => 
                    <div 
                        key={type.id}
                        className={selectedType.id == type.id ? "type__bar__item active" : "type__bar__item"}
                        onClick={() => {
                            product.setSelectedType(type)
                            setSelectedType(type)
                        }}
                        onMouseEnter={() => setActiveType(type)}
                        onMouseLeave={() => setActiveType({})}
                    >
                        {type.name}
                        {
                        activeType.id == type.id
                        &&
                        user.user.role == "ADMIN"
                        &&
                        <span
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            <div 
                                className="type__bar__item__edit"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setEditedType(type)
                                    setTypeModalVisible(true)
                                }}
                            >
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 15.0129L8.413 14.9979L18.045 5.4579C18.423 5.0799 18.631 4.5779 18.631 4.0439C18.631 3.5099 18.423 3.0079 18.045 2.6299L16.459 1.0439C15.703 0.287902 14.384 0.291902 13.634 1.0409L4 10.5829V15.0129ZM15.045 2.4579L16.634 4.0409L15.037 5.6229L13.451 4.0379L15.045 2.4579ZM6 11.4169L12.03 5.4439L13.616 7.0299L7.587 13.0009L6 13.0059V11.4169Z"/>
                                    <path d="M2 19H16C17.103 19 18 18.103 18 17V8.332L16 10.332V17H5.158C5.132 17 5.105 17.01 5.079 17.01C5.046 17.01 5.013 17.001 4.979 17H2V3H8.847L10.847 1H2C0.897 1 0 1.897 0 3V17C0 18.103 0.897 19 2 19Z"/>
                                </svg>
                            </div>
                            <div className="type__bar__item__delete">
                                <svg 
                                    fill="none" 
                                    viewBox="6.5 2 29 29" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setIsConfirmDeleteTypeVisible(true)
                                        setDeleteTypeId(type.id)
                                    }}
                                >
                                    <path d="M14.2085 10.2083L28.7918 24.7917M14.2085 24.7917L28.7918 10.2083" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </span>
                        }
                    </div>
                )}

                <ConfirmationalModal 
                    isVisible={isConfirmDeleteTypeVisible} 
                    setIsVisible={setIsConfirmDeleteTypeVisible} 
                    onConfirm={handleDeleteTypeConfirm} 
                    onCancel={handleDeleteTypeCancel} 
                    text={'După ce vei șterge tipul, ser vor șterge toate produsele care se referă la el.'}
                />

                <ChangeTypeName isVisible={typeModalVisible} setIsVisible={setTypeModalVisible} id={editedType.id} setCurrentTypes={setAllTypes}/>
            </div>
        );
    }
    
}); 

export default TypeBar;