import {$authHost, $host} from "./index"

export const fetchSliderPages = async () => {
    const {data} = await $host.get('api/slider_page')
    return data
}


export const createSliderPage = async (sliderPage) => {
    const {data} = await $host.post('api/slider_page', sliderPage, { withCredentials: true })
    return data
}


export const updateSliderImage = async (id, images) => {
    const {data} = await $authHost.patch(`api/slider_page/update-img/${id}`, images, { withCredentials: true }) 
    return data
}

export const updateSliderLink = async (id, link) => {
    const {data} = await $authHost.patch(`api/slider_page/update-link/${id}`, {link}, { withCredentials: true })
    return data
}

export const removeSliderPage = async (id) => {
    const {data} = await $authHost.delete(`api/slider_page/${id}`, { withCredentials: true })
    return data
}