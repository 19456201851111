import { useEffect, useState } from 'react';
import './UserPage.scss'
import './UserPageMedia.scss'
import { useParams } from 'react-router-dom';
import { fetchOneUser } from '../../http/userApi';
import ToShop from '../../components/ToShop/ToShop';
import { STATISTIC_ROUTE } from '../../utils/consts';
import UserOrder from '../../components/UserOrder/UserOrder';
import { parseISO, isAfter, subMonths } from 'date-fns';

const UserPage = () => {
    const [user, setUser] = useState({order: []})
    const [userKey, setUserKey] = useState(0)
    const [isFiltered, setIsFiltered] = useState(false)
    const {id} = useParams()

    const updateUserPage = () => {
        setUserKey(prev => prev + 1);
    }

    const filterOrdersLastMonth = () => {
        const oneMonthAgo = subMonths(new Date(), 1);
    
        // Фильтруем заказы за последний месяц
        const filteredOrders = user.order.filter(order =>
            isAfter(parseISO(order.createdAt), oneMonthAgo)
        );
    
        // Подсчитываем количество заказов за последний месяц
        const ordersCount = filteredOrders.length;
    
        // Вычисляем общую сумму потраченную за последний месяц
        const totalSpend = filteredOrders.reduce((total, order) => total + order.orderSum, 0);
    
        // Обновляем пользователя с учетом отфильтрованных заказов и новых значений ordersCount и totalSpend
        const updatedUser = {
            ...user,
            order: filteredOrders,
            ordersCount,
            totalSpend
        };
    
        // Сохраняем обновленного пользователя в состояние
        setUser(updatedUser);
        setIsFiltered(true);
    };
    const fetchData = async () => {
        try {
            const userData = await fetchOneUser(id)
            setUser(userData)
        } catch (e) {
            console.log(e)
        } finally {
            setIsFiltered(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [userKey])

    return (
        <div className='user__page'>
            <div id="container">
                <ToShop text={'Înapoi la utilizatori'} route={STATISTIC_ROUTE} />
                <h2 className="user__page__header">
                    Utilizatorul: <span>{user.name}</span> <br />
                    Numărul: <span>0{user.phone}</span> <br />
                    Cumpărături: {user.ordersCount} <br />
                    A cheltuit în total: {user.totalSpend} lei
                </h2>

                <h2 
                    className='user__page__last__month'
                    onClick={() => isFiltered ? fetchData() : filterOrdersLastMonth()}
                >
                    {isFiltered ? 'De tot timpul' : 'Ultima lună'}
                </h2>

                <div className="user__page__order__list">
                    {user.order.length == 0 && <h2 className="user__page__order__list__header">Utilizatorul nu a comandat nimic...</h2>}
                    {user.order && user.order.map((order) => (
                        <UserOrder key={order.id} order={order} updateUserPage={updateUserPage}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UserPage;