import { makeAutoObservable, action } from "mobx";

export default class LoaderStore {
  _isLoading = false;
  _isTransparent = true;
  _timeoutId = null;

  constructor() {
    makeAutoObservable(this, {
      showLoader: action.bound,
      hideLoader: action.bound,
    });
  }

  showLoader(isTransparent = true) {
    this._isTransparent = isTransparent;
    this._isLoading = true;
  }

  hideLoader() {
      this._isLoading = false;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isTransparent() {
    return this._isTransparent;
  }
}
