import {$authHost, $host} from "./index"

export const registration = async (name, phone, password, code) => {
    const {data} = await $host.post('api/user/registration', {name, phone, password, code}, { withCredentials: true })
    if (!data.userData) {
        return {message: data.message};
    }
    return {userData: data.userData, message: data.message}
}

export const login = async (phone, password) => {
    const {data} = await $host.post('api/user/login', {phone, password}, { withCredentials: true })
    if (!data.userData) {
        return {message: data.message};
    }
    return {userData: data.userData, message: data.message}
}

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth', { withCredentials: true })
    return data
}

export const logout = async () => {
    const {data} = await $authHost.post('api/user/logout', {}, { withCredentials: true })
    return data
}

export const fetchUsers = async () => {
    const {data} = await $authHost.get('api/user/users', { withCredentials: true })
    return data
}

export const fetchOneUser = async (id) => {
    const {data} = await $authHost.get(`api/user/${id}`, { withCredentials: true })
    return data
}

export const incrementOrdersCount = async (id) => {
    const {data} = await $authHost.patch(`api/user/${id}/increment-orders`, {}, { withCredentials: true })
    return data
}

export const decrementOrdersCount = async (id) => {
    const {data} = await $authHost.patch(`api/user/${id}/decrement-orders`, {}, { withCredentials: true })
    return data
}

export const increaseTotalSpend = async (id, orderId) => {
    const {data} = await $authHost.patch(`api/user/${id}/${orderId}/increase-spend`, {}, { withCredentials: true })
    return data
}

export const decreaseTotalSpend = async (id, orderId) => {
    const {data} = await $authHost.patch(`api/user/${id}/${orderId}/decrease-spend`, {}, { withCredentials: true })
    return data
}

export const changePassword = async (newPassword, phone, code) => {
    const {data} = await $host.patch(`api/user/reset-pass`, {newPassword, phone, code})
    return data
}

