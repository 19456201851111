import React, { useContext, useEffect, useRef, useState } from 'react';
import './CreateProduct.scss'
import Dropdown from '../../Dropdown/Dropdown';
import { Context } from '../../../index';
import { createProduct } from '../../../http/productApi';
import './CreateProductMedia.scss'

const CreateProduct = ({isVisible, setIsVisible}) => {
    const { product, notification, loader } = useContext(Context);
    const [category, setCategory] = useState(null);
    const [type, setType] = useState(null);
    const [filteredTypes, setFilteredTypes] = useState([]);
    const [name, setName] = useState('');
    const [price, setPrice] = useState(null);
    const [info, setInfo] = useState([]);
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (category) {
            const types = product.types.filter(type => type.categoryId === category.id);
            setFilteredTypes(types);
            setType(null);
        } else {
            setFilteredTypes([]);
        }
    }, [category, product.types]);

    const addInfo = () => {
        setInfo([...info, {title: '', description: '', number: Date.now()}])
    }

    const removeInfo = (number) => {
        setInfo([...info.filter(i => i.number !== number)])
    }

    const changeInfo = (key, value, number) => {
        setInfo(info.map(i => i.number === number ? {...i, [key]: value} : i))
    }

    const selectFile = e => {
        setFile(e.target.files[0])
    }

    const addProduct = () => {
        loader.showLoader();

        if (!category) {
            return notification.showNotification('Alegeți categoria')
        }

        if (!type) {
            return notification.showNotification('Alegeți tipul')
        }

        if (!name) {
            return notification.showNotification('Întroduceți denumirea')
        }

        if (!price) {
            return notification.showNotification('Întroduceți prețul')
        }

        if (!file) {
            return notification.showNotification('Întroduceți fotografia')
        }

        if (info.length > 0) {
            for (let i = 0; i < info.length; i++) {
                if (!info[i].title) {
                    return notification.showNotification('Întroduceți info');
                }
    
                if (!info[i].description) {
                    return notification.showNotification('Întroduceți descrierea la info');
                }
            }
        }

        const formData = new FormData()
        formData.append('name', name)
        formData.append('price', `${price}`)
        formData.append('img', file)
        formData.append('categoryId', category.id)
        formData.append('typeId', type.id)
        formData.append('info', JSON.stringify(info))
        createProduct(formData)
        .then(data => {
            if (data.message === 'Succes!') {
                product.setProducts([...product.products, data.product])
            }
            setIsVisible(false)
            notification.showNotification(data.message)
            setName('')
            setPrice(null)
            setFile(null)
            setCategory(null)
            setType(null)
            setInfo([])
            setFilteredTypes([])

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        })
        .catch(error => notification.showNotification(`${error.response.data}`))
        .finally(() => {
            setTimeout(() => {
                loader.hideLoader();
            }, 300)
        })
    }

    return (
        <div 
            className={isVisible ? "create__product show" : "create__product"}
            onMouseDown={(e) => setIsVisible(false)}
        >
            <div 
                className={isVisible ? "create__product__content show" : "create__product__content"}
                onMouseDown={e => e.stopPropagation()}
            >
                <div 
                    className="create__product__close"
                    onClick={() => setIsVisible(false)}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3002 5.71022C18.2077 5.61752 18.0978 5.54397 17.9768 5.49379C17.8559 5.44361 17.7262 5.41778 17.5952 5.41778C17.4643 5.41778 17.3346 5.44361 17.2136 5.49379C17.0926 5.54397 16.9827 5.61752 16.8902 5.71022L12.0002 10.5902L7.11022 5.70022C7.01764 5.60764 6.90773 5.5342 6.78677 5.4841C6.6658 5.43399 6.53615 5.4082 6.40522 5.4082C6.27429 5.4082 6.14464 5.43399 6.02368 5.4841C5.90272 5.5342 5.79281 5.60764 5.70022 5.70022C5.60764 5.79281 5.5342 5.90272 5.4841 6.02368C5.43399 6.14464 5.4082 6.27429 5.4082 6.40522C5.4082 6.53615 5.43399 6.6658 5.4841 6.78677C5.5342 6.90773 5.60764 7.01764 5.70022 7.11022L10.5902 12.0002L5.70022 16.8902C5.60764 16.9828 5.5342 17.0927 5.4841 17.2137C5.43399 17.3346 5.4082 17.4643 5.4082 17.5952C5.4082 17.7262 5.43399 17.8558 5.4841 17.9768C5.5342 18.0977 5.60764 18.2076 5.70022 18.3002C5.79281 18.3928 5.90272 18.4662 6.02368 18.5163C6.14464 18.5665 6.27429 18.5922 6.40522 18.5922C6.53615 18.5922 6.6658 18.5665 6.78677 18.5163C6.90773 18.4662 7.01764 18.3928 7.11022 18.3002L12.0002 13.4102L16.8902 18.3002C16.9828 18.3928 17.0927 18.4662 17.2137 18.5163C17.3346 18.5665 17.4643 18.5922 17.5952 18.5922C17.7262 18.5922 17.8558 18.5665 17.9768 18.5163C18.0977 18.4662 18.2076 18.3928 18.3002 18.3002C18.3928 18.2076 18.4662 18.0977 18.5163 17.9768C18.5665 17.8558 18.5922 17.7262 18.5922 17.5952C18.5922 17.4643 18.5665 17.3346 18.5163 17.2137C18.4662 17.0927 18.3928 16.9828 18.3002 16.8902L13.4102 12.0002L18.3002 7.11022C18.6802 6.73022 18.6802 6.09022 18.3002 5.71022Z" fill="black"/>
                    </svg>

                </div>

                <h2>Creează produsul</h2>

                <Dropdown
                    options={product.categories}
                    optionName={'Categoria'}
                    selectedOption={category}
                    setSelectedOption={setCategory}
                />
                <Dropdown 
                    options={filteredTypes}
                    optionName={'Tipul'}
                    selectedOption={type}
                    setSelectedOption={setType}
                    isDisabled={!category}
                />

                <form 
                    className="create__product__form"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <input
                        className="create__product__form__input"
                        placeholder="Denumirea"
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />

                    <input
                        className="create__product__form__input"
                        placeholder="Prețul"
                        type="number"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                    />

                    <input
                        className='create__product__form__file'
                        type="file"
                        onChange={selectFile}
                        ref={fileInputRef}
                        accept="image/png, image/jpeg, image/jpg"
                    />

                    <button
                        className='create__product__form__info__add'
                        onClick={addInfo}
                    >
                        Adaugă info
                    </button>
                    {
                        info.map(i => 
                            <div className="create__product__form__info__wrapper" key={i.number}>
                                <div>
                                    <input
                                        className='create__product__form__info__input'
                                        value={i.title}
                                        onChange={(e) => {
                                            changeInfo('title', e.target.value, i.number)
                                        }}
                                        placeholder='Întrodu info'
                                    />
                                </div>
                                <div>
                                    <input
                                        className='create__product__form__info__input'
                                        value={i.description}
                                        onChange={(e) => changeInfo('description', e.target.value, i.number)}
                                        placeholder='Întrodu descrierea'
                                    />
                                </div>
                                <div>
                                    <button 
                                        className='create__product__form__info__delete'
                                        onClick={() => removeInfo(i.number)}
                                    >
                                        Șterge
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    
                </form>
                <div className="create__product__btn__wrapper">
                    <button 
                        className="create__product__btn"
                        onClick={() => addProduct()}
                    >
                        Creează
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateProduct;