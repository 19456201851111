import {makeAutoObservable} from "mobx";

export default class SliderStore {
    constructor() {
        this._sliderPages = []
        makeAutoObservable(this)
    }

    setSliderPages(sliderPages) {
        this._sliderPages = sliderPages
    }

    get sliderPages() {
        return this._sliderPages
    }
}