import './Order.scss'
import OrderItem from '../../components/OrderItem/OrderItem';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../index';
import { fetchOrders } from '../../http/orderApi';
import { observer } from 'mobx-react-lite';

const Order = observer(() => {
    const {order} = useContext(Context);
    const [orders, setOrders] = useState([]);
    const [orderKey, setOrderKey] = useState(0);

    const updateOrder = () => {
        setOrderKey(prev => prev + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const orderList = await fetchOrders()
                order.setOrders(orderList)
                setOrders(order.orders)
            } catch (e) {
                console.log(e)
            }
        }
        fetchData()
    }, [orderKey])


    return (
        <div className='order'>
            <div id="container">
                <h2 className="order__header">
                    Comenzi:
                </h2>

                {orders.length > 0
                    ? 
                    orders.map(order => 
                        <OrderItem 
                            key={order.id} 
                            userName={order.userName} 
                            userPhone={order.userPhone}
                            orderProducts={order.orderProducts} 
                            orderSum={order.orderSum} 
                            userId={order.userId}
                            orderId={order.id}
                            updateOrder={updateOrder}
                            createdAt={order.createdAt}
                            delivery={order.delivery}
                            address={order.address}
                        />
                    )
                    :
                    <h2 className="order__subheader">Nu sunt comenzi la moment...</h2>
                }
            </div>
        </div>
    );
});

export default Order;