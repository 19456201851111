import React, { useContext, useState } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';

const PromoSliderPage = observer(({link, id, img, mobImg, setImgVisible, setLinkVisible, setDeleteVisible, setCurrentId}) => {
    const {user} = useContext(Context);
    const [isImgLoaded, setIsImgLoaded] = useState(false);
    
    const handleImgLoad = () => {
        setIsImgLoaded(true);
    }

    return (
        <a className='promo__slider__link' href={link} target="blank">
            {user.user.role == 'ADMIN' 
            &&
            <div 
                className="promo__slider__edit__img"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCurrentId(id);
                    setImgVisible(true);
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="23px" viewBox="0 0 24 24">
                    <g fill="none">
                        <path stroke="white" strokeWidth="2" d="M3 11c0-3.771 0-5.657 1.172-6.828S7.229 3 11 3h2c3.771 0 5.657 0 6.828 1.172S21 7.229 21 11v2c0 3.771 0 5.657-1.172 6.828S16.771 21 13 21h-2c-3.771 0-5.657 0-6.828-1.172S3 16.771 3 13z"/>
                        <path fill="white" fillRule="evenodd" d="m19 13.585l-.02-.02c-.39-.39-.726-.726-1.026-.979c-.317-.267-.662-.502-1.088-.63a3 3 0 0 0-1.732 0c-.426.129-.77.363-1.088.63c-.3.253-.636.59-1.025.98l-.029.028c-.307.306-.487.486-.628.601l-.02.017l-.012-.023c-.087-.16-.189-.393-.36-.792l-.053-.124l-.022-.052c-.356-.83-.655-1.528-.95-2.054c-.305-.541-.685-1.05-1.277-1.346a3 3 0 0 0-1.597-.307c-.66.055-1.201.386-1.685.775c-.406.327-.86.77-1.388 1.297V13q0 .774.003 1.411l1.114-1.114c.69-.69 1.15-1.147 1.525-1.45c.37-.298.53-.335.6-.34a1 1 0 0 1 .532.102c.061.031.196.124.43.54c.236.42.493 1.016.877 1.912l.053.124l.017.038c.149.348.287.67.425.924c.145.265.355.583.709.802a2 2 0 0 0 1.398.27c.41-.073.723-.29.956-.482c.222-.184.47-.432.738-.7l.03-.03c.425-.425.701-.7.928-.891c.218-.184.32-.228.376-.245a1 1 0 0 1 .578 0c.056.017.158.061.376.245c.227.191.503.466.929.892l1.35 1.35c.046-.718.054-1.61.056-2.773" clipRule="evenodd"/>
                        <circle cx="16.5" cy="7.5" r="1.5" fill="white"/>
                    </g>
                </svg>                                
            </div>
            }
            {user.user.role == 'ADMIN' 
            &&
            <div 
                className="promo__slider__edit__link"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCurrentId(id);
                    setLinkVisible(true);
                }}
            >
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 15.0129L8.413 14.9979L18.045 5.4579C18.423 5.0799 18.631 4.5779 18.631 4.0439C18.631 3.5099 18.423 3.0079 18.045 2.6299L16.459 1.0439C15.703 0.287902 14.384 0.291902 13.634 1.0409L4 10.5829V15.0129ZM15.045 2.4579L16.634 4.0409L15.037 5.6229L13.451 4.0379L15.045 2.4579ZM6 11.4169L12.03 5.4439L13.616 7.0299L7.587 13.0009L6 13.0059V11.4169Z"/>
                    <path d="M2 19H16C17.103 19 18 18.103 18 17V8.332L16 10.332V17H5.158C5.132 17 5.105 17.01 5.079 17.01C5.046 17.01 5.013 17.001 4.979 17H2V3H8.847L10.847 1H2C0.897 1 0 1.897 0 3V17C0 18.103 0.897 19 2 19Z"/>
                </svg>
            </div>
            }

            {user.user.role == 'ADMIN' 
            &&
            <div 
                className="promo__slider__edit__basket"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCurrentId(id);
                    setDeleteVisible(true);
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="23px" viewBox="0 0 24 24"><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21q.512.078 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48 48 0 0 0-3.478-.397m-12 .562q.51-.088 1.022-.165m0 0a48 48 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a52 52 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a49 49 0 0 0-7.5 0"></path></svg>
            </div>
            }      
            <img
                src={process.env.REACT_APP_API_URL + `server-static/${img}`} 
                alt="PROMO" 
                onLoad={handleImgLoad}
                className={`promo__slider__img ${isImgLoaded && 'active'}`}
            />
            <img
                src={process.env.REACT_APP_API_URL + `server-static/${mobImg}`} 
                alt="PROMO" 
                onLoad={handleImgLoad}
                className={`promo__slider__img mobile ${isImgLoaded && 'active-mob'}`}
            />
        </a>
    );
});

export default PromoSliderPage;