import React, { useEffect, useState } from 'react';
import { sendSmsCode, sendSmsResetCode } from '../../http/smsApi';

const SmsResend = ({name, phone, password, isReset}) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false); // состояние кнопки
    const [cooldown, setCooldown] = useState(60); // таймер на отправку повторного СМС
    const [attempts, setAttempts] = useState(0); // количество попыток
    const [globalCooldown, setGlobalCooldown] = useState(0); // блокировка на час после 3 попыток

    useEffect(() => {
        // Таймер для разблокировки кнопки через минуту
        let timer;
        if (cooldown > 0 && isButtonDisabled) {
          timer = setInterval(() => {
            setCooldown((prevCooldown) => prevCooldown - 1);
          }, 1000);
        } else if (cooldown === 0) {
          setIsButtonDisabled(false);
        }
        return () => clearInterval(timer);
      }, [cooldown, isButtonDisabled]);

      useEffect(() => {
        // Таймер для глобальной блокировки
        let globalTimer;
        if (globalCooldown > 0) {
          globalTimer = setInterval(() => {
            setGlobalCooldown((prevGlobalCooldown) => prevGlobalCooldown - 1);
          }, 1000);
        }
        return () => clearInterval(globalTimer);
      }, [globalCooldown]);
    
      const handleSendSms = () => {
        if (globalCooldown > 0) return; // Если включен глобальный кулдаун
    
        if (!isReset) {
        // Отправка СМС здесь
        const data = sendSmsCode(name, +phone, password);
        } else {
          const data = sendSmsResetCode(+phone);
        }
    
        setAttempts((prevAttempts) => prevAttempts + 1);
        setIsButtonDisabled(true);
        setCooldown(60); // Устанавливаем кулдаун на минуту
    
        if (attempts >= 4) {
          // Если было 3 попытки — блокируем на 30 или 60 минут
          setGlobalCooldown(60 * 30); // 30 минут глобального кулдауна
        }
      };
  
    return (
        <div className='sms__resender'>
            <button 
                onClick={handleSendSms} 
                disabled={isButtonDisabled || globalCooldown > 0}
                className='sms__resender__btn'
            >
                {isButtonDisabled ? `Primește cod nou peste ${cooldown} secunde` : 'Primește codul din nou'}
            </button>

            {globalCooldown > 0 && (
                <p className='sms__resender__text'>Ați făcut prea multe încercări, încercați încă o dată mai târziu.</p>
            )}
        </div>
    );
};

export default SmsResend;