import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserStore from './store/UserStore';
import ProductStore from './store/ProductStore';
import OrderStore from './store/OrderStore';
import './index.scss';
import './indexMedia.scss';
import StatisticStore from './store/StatisticStore';
import NotificationStore from './store/NotificationStore';
import SliderStore from './store/SliderStore';
import LoaderStore from './store/LoaderStore';


export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider value={{
    user: new UserStore(),
    product: new ProductStore(),
    order: new OrderStore(),
    statistic: new StatisticStore(),
    notification: new NotificationStore(),
    slider: new SliderStore(),
    loader: new LoaderStore()
  }}>
      <App />
  </Context.Provider>
);

