import './PromoSlider.scss';
import './PromoSliderMedia.scss';
import { useContext, useState} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrevArrow from './PrevArrow';
import NextArrow from './NextArrow';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import PromoSliderPage from './PromoSliderPage';
import { removeSliderPage } from '../../http/sliderApi';
import ChangeSliderImg from '../modals/ChangeSliderImg/ChangeSliderImg';
import ChangeSliderLink from '../modals/ChangeSliderLink/ChangeSliderLink';
import ConfirmationalModal from '../modals/ConfirmationalModal/ConfirmationalModal';

const PromoSlider = observer(() => {
    const {slider, notification} = useContext(Context);
    const [isChangeSliderImgVisible, setIsChangeSliderImgVisible] = useState(false);
    const [isChangeSliderLinkVisible, setIsChangeSliderLinkVisible] = useState(false);
    const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
    const [currentId, setCurrentId] = useState(null)

    const settings = {
        infinite: true,
        dots: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    const handleConfirmDelete = async () => {
        const data = await removeSliderPage(currentId);
        if (data.message === 'Succes!') {
            slider.setSliderPages(slider.sliderPages.filter(i => i.id !== currentId));
        }
        notification.showNotification(data.message);
        setIsConfirmDeleteVisible(false);
    };

    const handleCancelDelete = () => {
        setIsConfirmDeleteVisible(false);
    }

    return (
        <div className="promo__slider">
            <div 
                className="slider-container"
                style={{maxHeight: "900px"}}
            >
                <Slider {...settings}>
                    {slider.sliderPages.map(page => (
                        <div key={page.id}>
                            <PromoSliderPage 
                                link={page.link} 
                                id={page.id}
                                img={page.img}
                                mobImg={page.mobImg}
                                setImgVisible={setIsChangeSliderImgVisible}
                                setLinkVisible={setIsChangeSliderLinkVisible}
                                setDeleteVisible={setIsConfirmDeleteVisible}
                                setCurrentId={setCurrentId}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
            <ChangeSliderImg 
                isVisible={isChangeSliderImgVisible} 
                setIsVisible={setIsChangeSliderImgVisible}
                id={currentId}
            />
            <ChangeSliderLink
                isVisible={isChangeSliderLinkVisible}
                setIsVisible={setIsChangeSliderLinkVisible}
                id={currentId}
            />
            <ConfirmationalModal
                isVisible={isConfirmDeleteVisible} 
                setIsVisible={setIsConfirmDeleteVisible} 
                onConfirm={handleConfirmDelete} 
                onCancel={handleCancelDelete} 
                text={'Pagina alesă va fi ștearsă!'} 
            />
        </div>
    );
});

export default PromoSlider;
