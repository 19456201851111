import { useNavigate } from 'react-router-dom';
import { SHOP_ROUTE } from '../../utils/consts';
import './ToShop.scss'

import React, { useContext } from 'react';
import { Context } from '../../index';

const ToShop = ({text, route}) => {
    const navigate = useNavigate()
    const {loader} = useContext(Context)

    return (
        <h2 className="to__shop"
            onClick={() => {
            loader.showLoader(false);
            setTimeout(() => {
                navigate(route || SHOP_ROUTE)
            }, 500);
            }}
        > 
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.29605 12L12.788 3.273C12.858 3.20267 12.9132 3.11917 12.9507 3.02734C12.9881 2.93551 13.007 2.83717 13.0061 2.738C13.0053 2.63884 12.9848 2.54082 12.9459 2.44962C12.9069 2.35843 12.8503 2.27586 12.7792 2.2067C12.7081 2.13754 12.624 2.08317 12.5318 2.04672C12.4396 2.01027 12.341 1.99248 12.2419 1.99437C12.1427 1.99626 12.0449 2.01779 11.9542 2.05772C11.8634 2.09765 11.7814 2.15519 11.7131 2.227L2.71305 11.477C2.57683 11.617 2.50061 11.8047 2.50061 12C2.50061 12.1953 2.57683 12.383 2.71305 12.523L11.7131 21.773C11.7814 21.8448 11.8634 21.9023 11.9542 21.9423C12.0449 21.9822 12.1427 22.0037 12.2419 22.0056C12.341 22.0075 12.4396 21.9897 12.5318 21.9533C12.624 21.9168 12.7081 21.8625 12.7792 21.7933C12.8503 21.7241 12.9069 21.6416 12.9459 21.5504C12.9848 21.4592 13.0053 21.3612 13.0061 21.262C13.007 21.1628 12.9881 21.0645 12.9507 20.9727C12.9132 20.8808 12.858 20.7973 12.788 20.727L4.29605 12Z" fill="white"/>
            </svg>
            {text || `Pagina principală`}
        </h2>
    );
};

export default ToShop;