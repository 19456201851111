import CreateCategory from "../../components/modals/CreateCategory/CreateCategory";
import CreateProduct from "../../components/modals/CreateProduct/CreateProduct";
import { useContext, useState } from "react";
import { Context } from "../../index";
import './Admin.scss'
import CreateType from "../../components/modals/CreateType/CreateType";
import CreateSliderPage from "../../components/modals/CreateSliderPage/CreateSliderPage";
import './AdminMedia.scss'
import { useNavigate } from "react-router-dom";
import { ORDER_ROUTE, STATISTIC_ROUTE } from "../../utils/consts";

const Admin = () => {
    const {user} = useContext(Context)
    const navigate = useNavigate()
    const [typeVisible, setTypeVisible] = useState(false)
    const [categoryVisible, setCategoryVisible] = useState(false)
    const [productVisible, setProductVisible] = useState(false)
    const [sliderVisible, setSliderVisible] = useState(false)

    return (
        <div className="admin">
            {user.role === "ADMIN" ? 
            <div id="container">
                <div className="admin__panel">
                    <h2 className="admin__panel__header">Adaugă content nou</h2>
                    <button 
                        className="admin__panel__button"
                        onClick={() => setProductVisible(true)}
                    >
                        Adaugă produs
                    </button>
                    <button 
                        className="admin__panel__button"
                        onClick={() => setCategoryVisible(true)}
                    >
                        Adaugă categorie
                    </button>
                    <button 
                        className="admin__panel__button"
                        onClick={() => setTypeVisible(true)}
                    >
                        Adaugă tip de produse
                    </button>
                    <button 
                        className="admin__panel__button"
                        onClick={() => setSliderVisible(true)}
                    >
                        Adaugă un banner nou
                    </button>

                    <h2 className="admin__panel__header">Vezi comenzile sau lista utilizatorilor</h2>

                    <button 
                        className="admin__panel__button"
                        onClick={() => navigate(ORDER_ROUTE)}
                    >
                        Comenzile
                    </button>
                    
                    <button 
                        className="admin__panel__button"
                        onClick={() => navigate(STATISTIC_ROUTE)}
                    >
                        Utilizatorii
                    </button>
                </div>

                <CreateProduct isVisible={productVisible} setIsVisible={setProductVisible}/>
                <CreateCategory isVisible={categoryVisible} setIsVisible={setCategoryVisible}/>
                <CreateType isVisible={typeVisible} setIsVisible={setTypeVisible}/>
                <CreateSliderPage isVisible={sliderVisible} setIsVisible={setSliderVisible}/>
            </div> 
            : 
            "error"}
        </div>
    );
};

export default Admin;