import {$authHost} from "./index"

export const createUserOrder = async (order) => {
    const {data} = await $authHost.post('api/user_order', order, { withCredentials: true })
    return data
}

export const fetchUserOrders = async () => {
    const {data} = await $authHost.get(`api/user_order`, { withCredentials: true })
    return data
}

export const removeUserOrder = async (id) => {
    const {data} = await $authHost.delete(`api/user_order/${id}`, { withCredentials: true })
    return data
}

