import {$authHost, $host} from "./index"

export const sendSmsCode = async (name, phone, password) => {
    const {data} = await $authHost.post('api/sms', {name, phone, password})
    return data
}

export const sendSmsResetCode = async (phone) => {
    const {data} = await $authHost.post('api/sms/reset', {phone})
    return data
}

