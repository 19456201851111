
const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div
            className="promo__slider__arrow-next"
            onClick={onClick}
        >
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 7L9 12L14 17" stroke="white" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
};

export default NextArrow;