import {makeAutoObservable} from "mobx";

export default class ProductStore {
    constructor() {
        this._categories = []
        this._types = []
        this._products = []
        this._selectedCategory = {}
        this._selectedType = {}
        makeAutoObservable(this)
    }

    setCategories(categories) {
        this._categories = categories
    }
    setTypes(types) {
        this._types = types
    }

    setProducts(products) {
        this._products = products
    }

    setSelectedCategory(category) {
        this._selectedCategory = category
    }

    setSelectedType(type) {
        this._selectedType = type
    }

    get categories() {
        return this._categories
    }

    get types() {
        return this._types
    }

    get products() {
        return this._products
    }

    get selectedCategory() {
        return this._selectedCategory
    }
    get selectedType() {
        return this._selectedType
    }
}